import axios from "axios";
import { snackbar } from "./snackbarActions";

const bun_server = "https://geoserver.mapid.io";
// const bun_server = "http://localhost:4004";
// const bun_server = "https://h7jbqbdj-4004.asse.devtunnels.ms";

/*PROTOTYPE*/

export const get_sheets_by_cell = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    
    const { feature_key, field_key, geo_layer_id } = body;
    
    // Step 1: Initialize the base path
    let path = '/sheets?';
    
    // Step 2: Create an array to hold query parameters
    const queryParams = [];
    
    // Step 3: Add parameters to the array if they exist
    if (feature_key) queryParams.push(`feature_key=${feature_key}`);
    if (field_key) queryParams.push(`field_key=${field_key}`);
    if (geo_layer_id)  queryParams.push(`geo_layer_id=${geo_layer_id}`);

    // Step 4: Join the parameters with '&' and append to the path
    if (queryParams.length > 0) {
      path += queryParams.join('&');
    }

    // dispatch(setLoadingProcess("delete_project_to_recovery"));

    const res = await axios.get(
      bun_server + path,
      config
    );

    // dispatch(clearLoading());
    return res?.data;
  } catch (e) {
    return;
    // dispatch(clearLoading());
  }
};


export const create_sheet = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sheets`,
      body,
      config
    );

    return res?.data;
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

export const set_sheet_value = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.put(
      bun_server + `/sheets/sheet_value`,
      body,
      config
    );

    return res?.data;
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

export const clone_sheet = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sheets/clone`,
      body,
      config
    );

    return res?.data;
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

export const cell_ref = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sheets/cell_ref`,
      body,
      config
    );

    return res;
  } catch (e) {
    console.log("error:", e)
    return;
  }
};

export const delete_sheet_by_id = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const { _id } = body;

    if (_id) {
      const res = await axios.delete(
        bun_server + `/sheets/${_id}`,
        config
      );
      return res;
    }
    throw new Error("No id");

  } catch (e) {
    console.log("error:", e)
    return;
  }
};


export const edit_cell = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.put(
      bun_server + `/sheets/edit_cell`,
      body,
      config
    );

    dispatch(snackbar({
      is_open: true,
      status: "success",
      message: res?.data?.message || "Updated"
    }
    ));

    return res;

  } catch (e) {
    dispatch(snackbar({
      is_open: true,
      status: "error",
      message: e?.response?.data?.message || "Failed to update"
    }
    ));
    console.log("error:", e)
    return e?.response ;
  }
};


export const delete_cells = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sheets/delete_cells`,
      body,
      config
    );

    dispatch(snackbar({
      is_open: true,
      status: "success",
      message: res?.data?.message || "Deleted"
    }
    ));

    return res;

  } catch (e) {
    dispatch(snackbar({
      is_open: true,
      status: "error",
      message: e?.response?.data?.message || "Failed to delete"
    }
    ));
    console.log("error:", e)
    return e?.response;
  }
};


// export const setLoadingProcess = (itemLoading) => {
//   return {
//     type: "SET_LOADING_PROCESS_LAYER",
//     payload: itemLoading,
//   };
// };

// export const clearLoading = () => {
//   return {
//     type: "CLEAR_LOADING_LAYER",
//   };
// };