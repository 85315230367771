/*
UJI FISIK

"MIN_LUAS",
"MAX_LUAS",
"MIN_RASIO",
"MAX_RASIO",
"MIN_LEBAR_JALAN",

UJI SUPPLY DEMAND

"INDIKATOR_JML_PENDUDUK",
"JML_PENDUDUK",
"JML_MIN_POI",
"POI_PENDUKUNG",
"MAKS_RESISTEN",
"POI_RESISTEN_1KM",
"REKOMENDASI_PEMBANGUNAN"

UJI FINANSIAL

"BIAYA_PEMBANGUNAN_PER_M_2",
"PENDAPATAN_PER_M_2_MIN",
"PENDAPATAN_PER_M_2_MAX",
"BIOP_PER_M_2_MIN",
"BIOP_PER_M_2_MAX",

OTHER

"JENIS",
"TIPE_BANGUNAN",  
"HARGA_SEWA_PER_M_2_PER_TAHUN",
"OKUPANSI_PER_TAHUN",
"DISEWAKAN_DIGUNAKAN",
"FASUM",  
"RASIO_BIOP_MIN",
"RASIO_BIOP_MAX",
*/

export const calc_fs = (body) => async (dispatch) => {
  try {
    const {
      landvalue_value,
      luas_tanah,
      luas_bangunan,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      // discount_rate_2_percent,
      economic_life_years,
      tax_percent,
    } = body;
    let rows_filtered_3 = body?.rows_filtered_3 || [];

    rows_filtered_3 = rows_filtered_3.map((item) => {
      //step 1 menghitung capex
      const capex_tanah = parseFloat(luas_tanah) * parseFloat(landvalue_value);
      const capex_bangunan =
        parseFloat(luas_bangunan) * parseFloat(item?.BIAYA_PEMBANGUNAN_PER_M_2);
      const capex_total = capex_tanah + capex_bangunan;
      const depresiasi = capex_total / economic_life_years;

      const r_1 =
        parseFloat(item?.PENDAPATAN_PER_M_2_MAX) * parseFloat(luas_bangunan);
      const b_1 =
        parseFloat(item?.BIOP_PER_M_2_MIN) * parseFloat(luas_bangunan);

      item.depresiasi = depresiasi;
      item.r_1 = r_1;
      item.b_1 = b_1;

      //step 2 menghitung NPV
      let finance_array = [];
      for (let n = 1; n <= project_duration_years; n++) {
        //n mulai dari satu
        //n berakhir di project_duration_years, misal 10 tahun
        /*
        1. Pendapatan (R)
        Rn = R1 + (n-1) * a
        Rn = R1 + (n-1) * (revenue_growth_percent/100) * R1
        a = (revenue_growth_percent/100) * R1
        */
        const r_n = r_1 + (n - 1) * (revenue_growth_percent / 100) * r_1;

        /*
        2. Biaya Operasional (B)
        Bn = B1 + (n-1) * b
        Bn = B1 + (n-1) * (opex_growth_percent/100) * B1 
        b = (opex_growth_percent/100) * B1
        */
        const b_n = b_1 + (n - 1) * (opex_growth_percent / 100) * b_1;

        /*
        3. Earning After Tax (EAT)
        EATn = Rn - Bn - depresiasi - ( (tax_percent/100) * (Rn - Bn - depresiasi) )
        
        */
        const eat_n =
          r_n -
          b_n -
          depresiasi -
          (tax_percent / 100) * (r_n - b_n - depresiasi);

        /*
        4. Cash flow (CF)
        CFn = EATn + depresiasi 
        CFn = EATn + capex_total/economic_life_years 
        */
        const cf_n = eat_n + depresiasi;

        /*
        5. Present Value (PV)
        PVn = CFn * DRn
        PVn = CFn * ( 1 / (( 1 + discount_rate_1_percent/100 ) ** n_year ) )
        */
        const pv_n = cf_n * (1 / (1 + discount_rate_1_percent / 100) ** n);

        finance_array.push({
          r_1,
          b_1,
          r_n,
          b_n,
          eat_n,
          cf_n,
          pv_n,
        });
      }

      /*
      6. Net Present Value (PV)
      NPV = Σ PV - Σ investasi 
      NPV = Σ PV - (capex_total + working_capital_idr)
      */
      const sum_pv = finance_array.reduce((sum, item) => sum + item.pv_n, 0);
      const npv = parseInt(sum_pv - (capex_total + working_capital_idr));

      //step 3 menghitung IRR

      //step 4 menghitung ARR

      //step 5 menghitung Payback Period

      //step 6 menghitung Profitability Index
      return {
        ...item,
        capex_tanah,
        capex_bangunan,
        capex_total,
        finance_array,
        sum_pv,
        npv,
      };
    });

    dispatch({
      type: "set_value_hbu",
      payload: {
        key: "rows_filtered_3",
        value: rows_filtered_3,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
