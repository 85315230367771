// digunakan karena ada bug di async await
export const sort = (arr) => {
    if (arr.length <= 1) { return arr; }

    const pivot = arr[Math.floor(arr.length / 2)];
    const left = [];
    const right = [];

    for (let i = 0; i < arr.length; i++) {
        if (i === Math.floor(arr.length / 2)) continue; // Skip the pivot element  
        if (arr[i] < pivot) {
            left.push(arr[i]);
        } else {
            right.push(arr[i]);
        }
    }

    return [...sort(left), pivot, ...sort(right)];
}

export const find = (array, callback) => {
    for (let i = 0; i < array.length; i++) {
        if (callback(array[i], i, array)) {
            return array[i]; // Kembalikan elemen yang memenuhi kondisi  
        }
    }
    return undefined; // Kembalikan undefined jika tidak ada elemen yang memenuhi kondisi  
}

export const reduce = (array, reducer, initialValue) => {
    let accumulator = initialValue; // Inisialisasi akumulator dengan nilai awal  

    for (let i = 0; i < array.length; i++) {
        accumulator = reducer(accumulator, array[i], i, array); // Panggil fungsi reducer  
    }

    return accumulator; // Kembalikan nilai akumulator akhir  
}

export const map = (array, transform) => {
    const result = []; // Inisialisasi array hasil  

    for (let i = 0; i < array.length; i++) {
        result.push(transform(array[i], i, array)); // Panggil fungsi transformasi dan tambahkan ke hasil  
    }

    return result; // Kembalikan array hasil  
}

export const filter = (array, predicate) => {
    const result = []; // Inisialisasi array hasil  

    for (let i = 0; i < array.length; i++) {
        if (predicate(array[i], i, array)) { // Cek apakah elemen memenuhi kondisi  
            result.push(array[i]); // Tambahkan elemen ke hasil jika memenuhi  
        }
    }

    return result; // Kembalikan array hasil  
}
