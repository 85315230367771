const initial_state = {
  is_open: false,
  status: "info",
  message: "",
};

export default function snackbar_reducer(state = initial_state, action) {
  switch (action.type) {
    case "snackbar":
      return {
        ...state,
        is_open: action.payload.is_open,
        status: action.payload.status,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
