/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

/*COMPONENT*/
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import HBU_MANUAL from "./HBU_MANUAL";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class PARENT_HBU_MANUAL extends Component {
  state = {
    is_chat_ai_show: false,
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show,
    });
  };

  render() {
    //local storage

    //local state
    const { is_chat_ai_show } = this.state;

    //global props

    //content
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          height: "calc(100vh - 75px)",
        }}
      >
        <section
          style={{
            width: "50%",
          }}
        >
          <HBU_MANUAL worksheet={this.props.worksheet} />
        </section>
        <section
          style={{
            display: "flex",
            height: "100%",
            width: "50%",
            gap: "15px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ height: "50%" }}>
              <MAP_EXCEL />
            </div>
            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{ height: "50%" }}
            >
              SINI RESUME
            </div>
          </div>
          <div
            style={{
              width: "300px",
              overflowY: "auto",
            }}
          >
            <PARENT_SINI from="ai_hbu" />
          </div>
        </section>
        <section
          style={{
            visibility: is_chat_ai_show ? "visible" : "hidden",
            width: is_chat_ai_show ? "300px" : "0px",
            flexShrink: 0,
            flexGrow: 0,
            height: "100%",
          }}
        >
          SINI CHAT
        </section>
        <button
          className="floating_button circle_button center_perfect"
          onClick={this.toggle_chat_ai}
        >
          <div className="flex flex_col">
            <span className="w_full flex justify_center">
              <QuestionAnswerIcon />
            </span>
            <label className="font_10 pointer">Chat AI</label>
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(PARENT_HBU_MANUAL);
