import React, { Component } from "react";
import { Link } from "react-router-dom";

import information from "../../Assets/svg/information-circle.svg";
import arrow from "../../Assets/svg/arrow.svg";
import { connect } from "react-redux";
import VersionLog from "./VersionLog";
import { Modal } from "../reuseable";

import domain_list from "../../Data/domain_list";

class GeomapidVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_version: false,
    };
  }

  toggle_version = () => {
    const { modal_version } = this.state;
    this.setState({
      modal_version: !modal_version,
    });
  };
  render() {
    const { title, subtitle, auth, is_mobile } = this.props;
    const { modal_version } = this.state;
    const { version } = auth;

    //content
    const is_login_kai = domain_list?.[auth?.domain]?.is_login_kai;

    return (
      <main className="auth_content_left w_350">
        <div>
          <label>
            {" "}
            {title}{" "}
            <button className="hover_bigger" onClick={this.toggle_version}>
              <img className="info_icon" src={information} alt="What's new" />
            </button>
          </label>
          {!is_mobile && <label>{subtitle}</label>}

          <section>
            {!is_login_kai && <p className="flex wrap">Version {version}</p>}
            {!is_login_kai && <label className="padding_x_5">{" . "}</label>}
            <Link
              className="center_perfect bold text_white"
              to={{ pathname: "https://mapid.co.id" }}
              target="_blank"
              rel="noopener noreferer"
            >
              <p>
                <u>About Us</u>
              </p>{" "}
              <img src={arrow} alt="Landing Page" />
            </Link>
          </section>
        </div>
        {modal_version && (
          <Modal
            modalSize="medium"
            id="modal"
            isOpen={modal_version}
            onClose={this.toggle_version}
          >
            <div className="box-body">
              <VersionLog />
            </div>
          </Modal>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(GeomapidVersion);
