/*LIBRARY*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ModalNotification from "../common_modal/ModalNotification";
import ModalSearch from "../common_modal/ModalSearch";
import NotificationList from "./NotificationList";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ModalEmailConfirm from "../common_modal/ModalEmailConfirm";
import WhatsappConfirm from "./WhatsappConfirm";
import VersionLog from "./VersionLog";
import QoutaPriceList from "../payment/QoutaPriceList";
import PaymentTable from "../payment/PaymentTable";
import ErrorFree from "../user/ErrorFree";
import RedeemPopup from "../payment/RedeemPopup";

/*REDUX*/
import {
  refreshSearch,
  searchLayersOnly,
  searchProjects,
  searchGroups,
} from "../../App/actions/layerActions";
import {
  setLanguage,
  set_value_user,
  set_modal_quota_access,
} from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE*/
import icon_notification from "../../Assets/svg/icon_notification.svg";
import icon_map_editor from "../../Assets/svg/icon_map_editor.svg";
import icon_analyze_lite from "../../Assets/svg/icon_analyze_lite.svg";
import icon_form from "../../Assets/svg/icon_form_menu.svg";
import icon_group from "../../Assets/svg/icon_group.svg";
import icon_home from "../../Assets/svg/icon_home.svg";
import icon_profile from "../../Assets/svg/icon_profile.svg";
import icon_public_data from "../../Assets/svg/icon_public_data.svg";
import icon_simple_map from "../../Assets/svg/icon_simple_map.svg";
import icon_trash from "../../Assets/svg/icon_delete_grey.svg";
import icon_hamburger from "../../Assets/svg/icon_hamburger.svg";
import SINI_ILUSTRATION from "../../Assets/jsx/SINI_ILUSTRATION";
import icon_search from "../../Assets/svg/search.svg";

/*FUNCTION*/
import formatDate from "../../App/validation/format-date";

/*DATA*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*CONST*/
const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;

class Navigation extends Component {
  state = {
    width: window.innerWidth,
    search_params: "",
    mode_search: "layer",
    project_selected: {},
    layer_selected: {},
    show: 3,
    mode_auth: "login",
    anchor: null,
    modal_expand_sini: false,
    modal_project: false,
    modal_notification: false,
    modal_whatsapp: false,
    modal_search: false,
    loginModal: false,
    modal_version: false,
    is_menu_sidebar_open: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        loginModal: false,
      };
    } else return null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.timer = null;
    this.setState({
      modal_whatsapp: true,
    });
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.auth;
    const pathname_prev = prevProps.auth.pathname;
    if (pathname !== pathname_prev) {
      this.setState({
        modal_project: false,
        modal_notification: false,
        modal_search: false,
        loginModal: false,
      });
    }

    const isAuthenticated_after = this.props.auth.isAuthenticated;
    const isAuthenticated_before = prevProps.auth.isAuthenticated;

    if (isAuthenticated_after !== isAuthenticated_before) {
      this.props.set_value_user("modal_login", false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  toggle_sini_ai_trial = () => {
    this.props.set_value_sini({
      key: "modal_sini_ai_trial",
      value: !this.props.sini.modal_sini_ai_trial,
    });
  };

  toggle_expand_sini = (e) => {
    this.setState({ modal_expand_sini: !this.state.modal_expand_sini });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggle_buy_license = () => {
    const { modal_buy_license } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_buy_license",
      value: !modal_buy_license,
    });
  };

  toggle_version = () => {
    this.setState({ modal_version: !this.state.modal_version });
  };

  set_mode_auth = (mode_auth) => {
    this.setState({ mode_auth });
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  toggleProject = (project_selected, layer_selected) => {
    if (project_selected?.link) {
      this.setState({ project_selected });
    }
    if (layer_selected?.link) {
      this.setState({ layer_selected });
    }
    this.setState({ modal_project: !this.state.modal_project });
  };

  onChangeMode = (mode_search) => {
    clearTimeout(this.timer);
    this.setState({ mode_search });
  };

  toggleSearch = () => {
    this.props.refreshSearch();
    this.setState({ modal_search: !this.state.modal_search });
  };

  toggle_modal_quota_price = () => {
    this.props.set_modal_quota_access(false);
  };

  activateSearch = () => {
    this.setState({ modal_search: true });
  };

  closeSearch = () => {
    this.props.refreshSearch();
    this.setState({ modal_search: false, modal_project: false });
  };

  toggleNotification = () => {
    this.setState({ modal_notification: !this.state.modal_notification });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleLogin = () => {
    this.setState({
      loginModal: !this.state.loginModal,
    });
  };

  toggle_is_menu_sidebar_open = () => {
    const { is_menu_sidebar_open } = this.state;
    this.setState({
      is_menu_sidebar_open: !is_menu_sidebar_open,
    });
  };

  toggleWhatsappConfirm = () => {
    this.setState({
      modal_whatsapp: !this.state.modal_whatsapp,
    });
  };

  handleDontShow = () => {
    const { show } = this.state;
    localStorage.setItem("dontShow", show);
  };

  handleChange(e) {
    this.setState({ modal_search: true });
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_params, mode_search } = this.state;
    if (search_params) {
      switch (mode_search) {
        case "layer":
          this.props.searchLayersOnly(search_params);
          break;
        case "project":
          this.props.searchProjects(search_params);
          break;
        case "group":
          this.props.searchGroups(search_params);
          break;
        default:
          this.props.searchLayersOnly(search_params);
          break;
      }
    }
  }

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      search_params,
      modal_notification,
      modal_search,
      mode_search,
      modal_project,
      project_selected,
      layer_selected,
      width,
      modal_whatsapp,
      mode_auth,
      modal_version,
      modal_expand_sini,
      anchor,
      is_menu_sidebar_open,
    } = this.state;

    //global props
    let {
      domain,
      isAuthenticated,
      pathname,
      version,
      user,
      modal_login,
      modal_quota_access,
      modal_payment_universal,
    } = this.props.auth;
    const { modal_buy_license } = this.props.properties;
    const { search_result_list, loadingProcess, itemLoading } =
      this.props.layer;
    const { geo_layer_list, geo_project_list, group_list, is_fresh } =
      search_result_list;
    const { modal_sini_ai_trial } = this.props.sini;
    const is_login_kai = domain_list?.[this.props?.auth?.domain]?.is_login_kai;

    //content
    const is_mobile = width <= 900;
    const premium_status = domain_list?.[domain]?.status;

    // variable
    const is_confirmed =
      !(user?.is_email_confirm !== "not_propose") &&
      !(user?.is_whatsapp_confirm !== "not_propose");

    let array_menu = [
      {
        title: "Insight",
        icon: icon_simple_map,
        link: "/insight",
        links: ["/insight"],
        button_mode: "menu_expand", //menu_expand || link
        toggle_action: this.toggle_expand_sini,
      },
      {
        title: dict["Dashboard"][language],
        icon: icon_home,
        link: "/dashboard",
        links: ["/dashboard"],
      },
      {
        title: dict["Profile"][language],
        icon: icon_profile,
        link: "/user_profile",
        links: ["/user_profile", "/purchase_list"],
      },
      {
        title: dict["Group"][language],
        icon: icon_group,
        link: "/user_group",
        links: ["/user_group"],
      },
      {
        title: dict["Public Data"][language],
        icon: icon_public_data,
        link: "/",
        links: [
          "/",
          "/publication_free",
          "/publication_paid",
          "/publication_project",
        ],
      },
    ];

    let array_menu_public = [
      {
        title: dict?.["Login"]?.[language],
        icon: icon_profile,
        link: "/login",
        links: ["/login"],
      },
      {
        title: dict?.["Public Data"]?.[language],
        icon: icon_public_data,
        link: "/",
        links: [
          "/",
          "/publication_free",
          "/publication_paid",
          "/publication_project",
        ],
      },
    ];

    if (premium_status === "premium") {
      array_menu = [
        {
          title: "Insight",
          icon: icon_simple_map,
          link: "/insight",
          links: ["/insight"],
          button_mode: "menu_expand", //menu_expand || link
          toggle_action: this.toggle_expand_sini,
        },
        {
          title: dict["Dashboard"][language],
          icon: icon_home,
          link: "/dashboard",
          links: ["/dashboard"],
        },
        {
          title: dict["Profile"][language],
          icon: icon_profile,
          link: "/user_profile",
          links: ["/user_profile"],
        },
        {
          title: dict["Group"][language],
          icon: icon_group,
          link: "/user_group",
          links: ["/user_group"],
        },
      ];
      array_menu_public = [
        {
          title: dict["Login"][language],
          icon: icon_profile,
          link: "/login",
          links: ["/login"],
        },
      ];
    }

    let array_menu_final = isAuthenticated ? array_menu : array_menu_public;
    const mode_array = [
      {
        name: dict["Layer"][language],
        key: "layer",
      },
      {
        name: dict["Project"][language],
        key: "project",
      },
      {
        name: dict["Group"][language],
        key: "group",
      },
    ];
    let list_result;

    if (
      loadingProcess &&
      ["search_layer", "search_projects", "search_groups"].includes(itemLoading)
    ) {
      list_result = <div>{dict["Searching"][language]}...</div>;
    } else if (mode_search === "layer") {
      if (geo_layer_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict["There are no layers with the keyword you are looking for"][
                language
              ]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start looking for layers with the keywords you want to search for"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <div>
            {geo_layer_list.map((layer) => {
              const { _id, name, type, geo_project, user, date } = layer;
              const geo_project_name = geo_project?.name
                ? geo_project?.name
                : dict["Project already delete"][language];
              const user_name = user?.full_name || user?.name || "No user";
              return (
                <main key={_id} className="result_item">
                  <section className="result_name">{name}</section>
                  <section
                    onClick={this.toggleProject.bind(this, geo_project, layer)}
                    className="button background_blue"
                  >
                    {dict["Open"][language]} {dict["Project"][language]}:{" "}
                    <b>{geo_project_name}</b>
                  </section>
                  <br />
                  <section className="badge_normal">
                    {dict["Type"][language]}: <b>{type}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Owner"][language]}: <b>{user_name}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Update at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </div>
        );
      }
    } else if (mode_search === "project") {
      if (geo_project_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "There are no projects with the keywords you are looking for"
              ][language]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start searching for projects with the keywords you want to search"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <main>
            {geo_project_list.map((project) => {
              const { _id, name, user, date } = project;
              const user_name = user?.full_name || user?.name || "No user";
              return (
                <main key={_id} className="result_item">
                  <section onClick={this.toggleProject.bind(this, project)}>
                    <div className="result_name text_medium">{name}</div>
                  </section>
                  <section
                    onClick={this.toggleProject.bind(this, project)}
                    className="button background_blue"
                  >
                    {dict["Open"][language]} {dict["Project"][language]}
                  </section>
                  <br />
                  <section className="badge_normal">
                    {dict["Owner"][language]}: <b>{user_name}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Update at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </main>
        );
      }
    } else {
      if (group_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict["There are no groups with the keyword you are looking for"][
                language
              ]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start searching for groups with the keywords you want to search"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <div>
            {group_list.map(({ _id, name, members, date }) => {
              return (
                <main key={_id} className="result_item">
                  <Link
                    to={`/group/${_id}`}
                    className="result_name"
                    onClick={this.closeSearch}
                  >
                    {name}
                  </Link>
                  <br />
                  <Link
                    to={`/group/${_id}`}
                    onClick={this.closeSearch}
                    className="button background_blue"
                  >
                    {dict["Open"][language]} {dict["Group"][language]}
                  </Link>
                  <br />
                  <section className="badge_normal">
                    {dict["Members"][language]}: <b>{members.length}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Create at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </div>
        );
      }
    }

    const modal_quota_price_list = modal_quota_access && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_quota_access}
        onClose={this.toggle_modal_quota_price}
      >
        <div className="box-body">
          <header className="center_perfect">
            <h1 className="text_header marginBottom_50">
              Beli akses untuk SINI AI dan INSIGHT
            </h1>
          </header>
          <QoutaPriceList />
        </div>
      </Modal>
    );

    const search_result_content = modal_search && (
      <ModalSearch
        modalSize="small"
        id="modal"
        isOpen={modal_search}
        onClose={this.toggleSearch}
      >
        <div className="box-body">
          <div className="search_bar">
            <input
              type="text"
              placeholder={
                dict["Search your layer, project or group"][language]
              }
              autoComplete="off"
              name="search_params"
              value={search_params}
              onChange={this.handleChange.bind(this)}
              onKeyDown={this.handleKeyDown.bind(this)}
              onFocus={this.activateSearch}
              style={{
                overflow: "hidden",
                boxSizing: "border-box",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "15px",
                color: "#575757",
                textAlign: "left",
                zIndex: "100",
              }}
            />
          </div>
          <section style={{ marginBottom: "10px" }}>
            {mode_array.map((e) => {
              let css_id = "outline_white";
              if (mode_search === e.key) {
                css_id = "outline_blue";
              }
              return (
                <div
                  key={e.key}
                  onMouseDown={this.onChangeMode.bind(this, e.key)}
                  onClick={this.onChangeMode.bind(this, e.key)}
                  className="button_very_small"
                  id={css_id}
                  style={{ marginRight: "5px" }}
                >
                  {e.name}
                </div>
              );
            })}
          </section>
          {list_result}
        </div>
      </ModalSearch>
    );

    const modal_notification_content = modal_notification && (
      <ModalNotification
        modalSize="small"
        id="modal"
        isOpen={modal_notification}
        onClose={this.toggleNotification}
      >
        <div className="box-body">
          <NotificationList />
        </div>
      </ModalNotification>
    );

    const array_option = [
      {
        name: "Map Editor",
        icon: icon_map_editor,
        link:
          mode_search === "layer"
            ? `/editor/${project_selected.link}?layer_id=${layer_selected._id}`
            : `/editor/${project_selected.link}`,
      },

      {
        name: "Map Viewer",
        icon: icon_analyze_lite,
        link:
          mode_search === "layer"
            ? `/view/${project_selected.link}?layer_id=${layer_selected._id}`
            : `/view/${project_selected.link}`,
      },
      {
        name: "Form Editor",
        icon: icon_form,
        link: `/form_editor/${layer_selected.link}`,
      },
    ];

    const modal_project_content = modal_project && (
      <ModalSearch
        id="modal"
        isOpen={modal_project}
        onClose={this.toggleProject}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          {array_option.map((e, idx) => {
            let item = null;
            if (idx !== 3 || (idx === 3 && mode_search === "layer")) {
              item = (
                <Link key={e.name} to={e.link} onClick={this.closeSearch}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "77px",
                      margin: "20px",
                      verticalAlign: "top",
                    }}
                  >
                    <img
                      src={e.icon}
                      height="45px"
                      alt={e.name}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="text_bold" style={{ fontSize: "14px" }}>
                      {e.name}
                    </div>
                  </div>
                </Link>
              );
            }
            return item;
          })}
        </div>
      </ModalSearch>
    );

    const language_list = [
      {
        name: "INA",
        value: "ina",
        image: "https://mapid.co.id/img/icon/indo-flag.png",
      },
      {
        name: "ENG",
        value: "eng",
        image: "https://mapid.co.id/img/icon/uk-flag.png",
      },
    ];

    const language_content = (
      <main style={{ marginTop: "10px" }}>
        {language_list.map((e) => {
          let css_id = "outline_white";
          if (e.value === language) {
            css_id = "outline_blue";
          }
          return (
            <div
              onClick={this.onSetLanguage.bind(this, e.value)}
              key={e.value}
              className="button_pill_tight"
              id={css_id}
              style={{ marginRight: "5px", marginBottom: "5px" }}
            >
              <img
                style={{
                  width: "20px",
                  height: "20px",
                }}
                src={e.image}
                alt={e.name}
              />
            </div>
          );
        })}
      </main>
    );

    let nav_content = (
      <main id="navbarDefault" className="navbarDefault">
        <section className="top_nav" id="top_nav">
          <div className="top_nav_container">
            <div
              className={`center_perfect gap_15 margin_x_5 ${
                width <= 900 ? "w_250" : "w_200"
              } `}
            >
              {width <= 900 && (
                <div
                  onClick={this.toggle_is_menu_sidebar_open}
                  className="w_30 h_30 pointer"
                >
                  <img src={icon_hamburger} height="30px" alt={"menu"} />
                </div>
              )}
              <Link to="/dashboard">
                <div className="center_perfect">
                  <img
                    src={domain_list?.[domain]?.logo}
                    height="30px"
                    alt={"icon"}
                  />
                </div>
              </Link>
            </div>

            <div className="flex justify_end align_center gap_10 marginRight_20">
              {isAuthenticated && (
                <>
                  {width <= 900 ? (
                    <button>
                      <img
                        src={icon_search}
                        width={"20px"}
                        onClick={this.activateSearch}
                        alt="Search"
                      />
                    </button>
                  ) : (
                    <div className="search_bar">
                      <input
                        type="text"
                        placeholder={
                          dict["Search your layer, project or group"][language]
                        }
                        autoComplete="off"
                        name="search_params"
                        value={search_params}
                        onChange={this.handleChange.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onFocus={this.activateSearch}
                        style={{
                          overflow: "hidden",
                          boxSizing: "border-box",
                          border: "none",
                          backgroundColor: "transparent",
                          fontSize: "15px",
                          color: "#575757",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {isAuthenticated && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleNotification}
                >
                  <img src={icon_notification} height="25px" alt="GEO MAPID" />
                </div>
              )}
            </div>
          </div>
        </section>
        <section
          className={`sidebar_home sidebar_dashboard_layout ${
            is_menu_sidebar_open || !is_mobile ? "open" : ""
          }`}
        >
          <div>
            {array_menu_final.map(
              (
                { title, icon, link, links, button_mode, toggle_action },
                idx
              ) => {
                const id_content = links.includes(pathname)
                  ? "active"
                  : "not_active";
                let content = (
                  <Link
                    to={link}
                    onClick={() => {
                      if (width <= 900) this.toggle_is_menu_sidebar_open();
                    }}
                    className="sidebar_button vertical_center w_200"
                    id={id_content}
                    key={idx}
                    data-mapid={`clickButton-${idx}`}
                  >
                    <div className="vertical_center_child">
                      <div style={{ marginLeft: "20px" }}>
                        <img src={icon} alt={title} width="35px" />
                      </div>
                      <div
                        className="text_medium"
                        style={{ marginLeft: "20px", width: "250px" }}
                      >
                        {title}
                      </div>
                    </div>
                  </Link>
                );

                if (button_mode === "menu_expand") {
                  content = (
                    <div
                      className="sidebar_button vertical_center w_200"
                      id={id_content}
                      onClick={toggle_action}
                      data-mapid="clickInsight"
                    >
                      <div className="vertical_center_child">
                        <div style={{ marginLeft: "20px" }}>
                          <img src={icon} alt={title} width="35px" />
                        </div>
                        <div
                          className="text_medium"
                          style={{ marginLeft: "20px", width: "250px" }}
                        >
                          {title}
                        </div>
                      </div>
                    </div>
                  );
                }

                return <div key={idx}>{content}</div>;
              }
            )}

            {isAuthenticated && premium_status !== "premium" && (
              <RedeemPopup language={language} />
            )}
          </div>
          <div
            style={{ marginLeft: "20px", marginTop: "60px" }}
            className="text_medium"
          >
            {domain_list?.[domain]?.short_title}
            {!is_login_kai && (
              <>
                <br />V {version}{" "}
              </>
            )}
            <span
              className="button_pill_tight"
              id="green"
              onClick={this.toggle_version}
            >
              Changelogs
            </span>
            <div>{language_content}</div>
          </div>

          {isAuthenticated && (
            <div className="flex align_end">
              <div>
                <div className="paddingLeft_15 w_175">
                  <hr />
                </div>
                <Link
                  to={"/recovery"}
                  onClick={() => {
                    if (width <= 900) this.toggle_is_menu_sidebar_open();
                  }}
                  className="sidebar_button vertical_center w_200"
                  id={pathname === "/recovery" ? "active" : "not_active"}
                  key={"Recovery"}
                >
                  <div className="vertical_center_child">
                    <div style={{ marginLeft: "20px" }}>
                      <div className="bg_lightGrey rounded_full center_perfect w_35 h_35">
                        <img
                          src={icon_trash}
                          style={{
                            filter: "invert(1)",
                          }}
                          alt={dict["Recovery"][language]}
                          width="20px"
                        />
                      </div>
                    </div>
                    <div
                      className="text_medium"
                      style={{ marginLeft: "20px", width: "250px" }}
                    >
                      {dict["Recovery"][language]}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </section>
      </main>
    );

    const modal_whatsapp_confirm = modal_whatsapp && (
      <>
        {is_confirmed ? (
          <ModalEmailConfirm
            modalSize="medium"
            id="modal"
            isOpen={modal_whatsapp}
            onClose={this.toggleWhatsappConfirm}
            auth={this.props.auth}
          >
            <WhatsappConfirm onClose={this.toggleWhatsappConfirm} />
          </ModalEmailConfirm>
        ) : null}
      </>
    );

    const modal_login_content = modal_login && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_login}
        onClose={this.toggle_login}
      >
        <div className="box-body">
          {mode_auth === "login" ? (
            <Login isNav={true} />
          ) : (
            <Register isNav={true} />
          )}
          {mode_auth === "login" ? (
            <section style={{ display: "block", marginTop: "30px" }}>
              <p className="button_account">
                {dict["Don't have an account?"][language]}
              </p>
              <p
                className="button_register"
                onClick={this.set_mode_auth.bind(this, "register")}
              >
                {dict["Register here"][language]}
              </p>
            </section>
          ) : (
            <section style={{ display: "block", marginTop: "30px" }}>
              <p className="button_account">
                {dict?.["Already have an account?"]?.[language]}
              </p>
              <p
                className="button_register"
                onClick={this.set_mode_auth.bind(this, "login")}
              >
                {dict["Login instead"][language]}
              </p>
            </section>
          )}
          {language_content}
        </div>
      </Modal>
    );

    const modal_version_content = modal_version && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_version}
        onClose={this.toggle_version}
      >
        <div className="box-body">
          <VersionLog />
        </div>
      </Modal>
    );

    const modal_buy_license_content = modal_buy_license && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_buy_license}
        onClose={this.toggle_buy_license}
      >
        <div className="box-body">
          <div className="marginBottom_20">
            <label className="font_30 bold">
              {dict["Best license price for you"][language]}
            </label>
            <p>
              {
                dict[
                  "Get features at the best price on the market! Limited timeoffer to boost your productivity."
                ][language]
              }
            </p>
          </div>
          <PaymentTable />
        </div>
      </Modal>
    );

    const modal_payment_universal_content = modal_payment_universal && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_payment_universal}
        onClose={this.toggle_payment_universal}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    const modal_expand_sini_content = modal_expand_sini && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_expand_sini}
        onClose={this.toggle_expand_sini}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
          }}
        >
          <Link
            to="/sini"
            className="button_big margin_bottom button_white"
            onClick={this.toggle_expand_sini}
            data-mapid="clickSiniDropDown"
          >
            SINI AI
          </Link>
          <br />
          <Link
            to="/insight"
            className="button_big margin_bottom button_white"
            onClick={this.toggle_expand_sini}
            data-mapid="clickInsightDropDown"
          >
            INSIGHT
          </Link>
        </div>
      </Menu>
    );

    const modal_sini_ai_trial_content = modal_sini_ai_trial && (
      <Modal
        modalSize="small"
        is_hide="hide"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="30px"
        isOpen={modal_sini_ai_trial}
        onClose={this.toggle_sini_ai_trial}
      >
        <div
          className="box-body"
          id="box-body"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
            maxHeight: "75vh",
          }}
        >
          <main>
            <section style={{ textAlign: "center" }}>
              <h1>Redeem akses SINI AI trial berhasil!</h1>
              <br />
              <br />
              <br />
              <SINI_ILUSTRATION />
              <br />
              <br />
              <br />
              <Link to="/sini" className="button_big">
                Pakai sekarang
              </Link>
            </section>
          </main>
        </div>
      </Modal>
    );

    return (
      <div>
        {modal_sini_ai_trial_content}
        {modal_expand_sini_content}
        {modal_buy_license_content}
        {modal_version_content}
        {modal_login_content}
        {modal_notification_content}
        {modal_project_content}
        {modal_whatsapp_confirm}
        {modal_payment_universal_content}
        {search_result_content}
        {modal_quota_price_list}
        {nav_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  sini: state.sini,
});

export default connect(mapStateToProps, {
  searchLayersOnly,
  searchProjects,
  searchGroups,
  setLanguage,
  refreshSearch,
  set_value_user,
  set_modal_quota_access,
  set_value_properties,
  set_value_sini,
})(Navigation);
