import React, { Component } from "react";
import Modal from "../common_modal/Modal";
import is_equal_array from "../../App/validation/is_equal_array";

export default class Checklist extends Component {
  constructor(props) {
    super(props);
    // this.optionChecklist= this.props.params?.colDef?.array_templates || [],
    // this.arrayOfStrOptionChecklist = this.state.optionChecklist.map(
    //   (item) => item.name
    // );
    this.state = {
      value_array: [],
      modalChecklist: false,
      isAllowOtherAnswer: false,
      other_array: [],
      newChecklistName: "",
      optionChecklist: "",
      arrayOfStrOptionChecklist: [],
    };
  }

  componentDidMount = () => {
    if (!this.state.optionChecklist) {
      this.setTheChecklistOptions();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // console.log("this", this.props.params?.colDef?.array_templates);
    // console.log("prev", prevProps.params?.colDef?.array_templates);
    // console.log("this row", [this.props.params.row?.[this.props.column?.key]]);
    // console.log("prev row", [prevProps.params.row?.[this.props.column?.key]]);
    // console.log("==================================");

    if (
      !is_equal_array(
        this.props.params?.colDef?.array_templates,
        prevProps.params?.colDef?.array_templates
      ) ||
      !is_equal_array(
        [this.props.params.row?.[this.props.column?.key]],
        [prevProps.params.row?.[this.props.column?.key]]
      )
    ) {
      this.setTheChecklistOptions();
    }
  };

  setTheChecklistOptions = () => {
    const optionChecklist = this.props.params?.colDef?.array_templates || [];
    const arrayOfStrOptionChecklist = optionChecklist.map((item) => item?.name);
    const params_value = this.props.params?.value || null;
    let splitted_params_value = [];
    if (typeof params_value === "string") {
      splitted_params_value = params_value?.split("\n");
    } else {
      splitted_params_value = [];
    }

    this.setState({
      optionChecklist: optionChecklist,
      arrayOfStrOptionChecklist: arrayOfStrOptionChecklist,
      value_array:
        splitted_params_value?.filter((value) =>
          arrayOfStrOptionChecklist.includes(value)
        ) || [],
      other_array:
        splitted_params_value?.filter(
          (value) => !arrayOfStrOptionChecklist?.includes(value)
        ) || [],
    });
  };

  toggleIsAllowOtherAnswer = () => {
    this.setState({ isAllowOtherAnswer: !this.state.isAllowOtherAnswer });
  };

  // ketika ada perubahan =====================

  checkingInsideValueArrayOrOtherArray = (value) => {
    const { other_array, value_array } = this.state;
    if (value_array?.includes(value)) {
      this.handleOnClick(value);
    } else if (other_array?.includes(value)) {
      this.handleRemoveOtherValue(value);
    }
  };

  handleOnClick = (value) => {
    let newArray = [...this.state.value_array];
    var index = newArray.indexOf(value);
    newArray.splice(index, 1);
    this.setState({ value_array: newArray }, () => {
      this.handleSubmit();
    });
  };

  handleChecklist = (params) => {
    let newArray = [];
    if (this.state.value_array?.includes(params?.target?.value)) {
      newArray = [...this.state.value_array];
      var index = newArray.indexOf(params?.target?.value);
      newArray.splice(index, 1);
    } else {
      newArray = [...this.state.value_array];
      newArray.push(params?.target?.value);
    }
    this.setState({ value_array: newArray }, () => {
      this.handleSubmit();
    });
  };

  handleAddOtherValue = () => {
    let newArray = [...this.state.other_array];
    newArray.unshift(this.state.newChecklistName);
    this.setState({ other_array: [...newArray] }, () => {
      this.handleSubmit();
    });
  };

  handleRemoveOtherValue = (value) => {
    let newArray = [...this.state.other_array];
    var index = newArray.indexOf(value);
    newArray.splice(index, 1);
    this.setState({ other_array: newArray }, () => {
      this.handleSubmit();
    });
  };

  // ===============================

  toggleModalChecklist = () => {
    this.setState({ modalChecklist: !this.state.modalChecklist });
  };

  handleSubmit = () => {
    const value = [...this.state?.value_array, ...this.state?.other_array].join(
      "\n"
    );

    let newParams = this.props.params;
    newParams.value = value;
    newParams.row.value = value;

    this.props.handleEditCellChange(
      this.props.editProperties,
      this.props.layer_id,
      newParams
    );
  };

  render() {
    const hideChecklist = this.state?.isAllowOtherAnswer &&
      this.props.column.isAllowOtherAnswer && (
        <>
          <div className="two_container_small_right center_perfect">
            <input
              onChange={(params) => {
                this.setState({ newChecklistName: params.target.value });
              }}
              type="text"
              // style={{ marginRight: 10, width: 150 }}
            />
            <button
              style={{ height: 40 }}
              className="button background_blue"
              onClick={(params) => {
                this.handleAddOtherValue();
              }}
            >
              add
            </button>
          </div>

          <div>
            {this.state.other_array?.length > 0 &&
              this.state.other_array?.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "100%",
                      marginBottom: 5,
                    }}
                  >
                    <div style={{ display: "inline", width: 100 }}>{value}</div>
                    <button
                      className="button background_blue"
                      style={{ display: "inline" }}
                      onClick={(params) => {
                        this.handleRemoveOtherValue(value);
                      }}
                    >
                      remove
                    </button>
                  </div>
                );
              })}
          </div>
        </>
      );

    const modalChecklist = this.state.modalChecklist && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalChecklist}
        onClose={(params) => this.toggleModalChecklist(params)}
      >
        <main
          className="container_flat padding_30"
          // style={{
          //   paddingTop: 5,
          //   paddingRight: 30,
          //   paddingLeft: 30,
          //   paddingBottom: 30,
          // }}
        >
          <section
            style={{
              marginBottom: 25,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h1>{this.props.column.name}</h1>
          </section>
          <div>
            <section className="scroll_y_auto h_full w_full flex gap_20">
              <div className="w_100 rounded_10 padding_5">
                {this.state.optionChecklist &&
                  this.state.optionChecklist?.map((value, index) => {
                    return (
                      <div key={index} className="two_container_list">
                        <div className="center_perfect inline">
                          <button
                            id={value._id}
                            className={`multi_check_v2 inline w_10 h_10 ${
                              this.state.value_array.includes(value.name)
                                ? "bg_blue"
                                : "bg_white"
                            }`}
                            value={value.name}
                            onClick={(params) => {
                              this.handleChecklist(params);
                            }}
                          />
                        </div>
                        <label className="inline wrap" htmlFor={value._id}>
                          {value.name}
                        </label>
                      </div>
                    );
                  })}
              </div>

              <div className="vertical_line" />

              <div
                style={{
                  width: 230,
                  padding: 10,
                }}
              >
                <div>
                  <div style={{ paddingRight: 10, display: "inline" }}>
                    Lainnya:
                  </div>
                  <button
                    style={{ display: "inline" }}
                    id={this.state.isAllowOtherAnswer ? "blue" : "white"}
                    className="multi_check margin_bottom"
                    type="checkbox"
                    value={this.state.isAllowOtherAnswer}
                    onClick={(params) => {
                      this.toggleIsAllowOtherAnswer();
                    }}
                  />
                </div>
                {this.state.isAllowOtherAnswer && hideChecklist}
              </div>
            </section>
          </div>
        </main>
      </Modal>
    );

    const { value_array, other_array } = this.state;
    const merge_array_values = [...value_array, ...other_array];

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <section
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: 5,
          }}
        >
          <button
            className="button hover_darkBlue"
            onClick={(params) => this.toggleModalChecklist()}
          >
            Edit
          </button>
        </section>
        <section
          style={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
            padding: 5,
          }}
        >
          {/* {this.props.params?.colDef?.array_templates?.length > 0 &&
            this.props.params?.colDef?.array_templates?.map((value, index) => {
              return (
                this.state.value_array?.includes(value.name) && (
                  <div key={index}>
                    <button
                      id="blue"
                      style={{ width: 10, height: 10 }}
                      className="multi_check margin_bottom side"
                      value={value.name}
                      onClick={(params) => {
                        this.handleOnClick(value.name);
                      }}
                    />
                    <span
                      className="side"
                      style={{ fontSize: 10 }}
                      htmlFor={value._id}
                    >
                      {value.name}
                    </span>
                  </div>
                )
              );
            })} */}
          {merge_array_values?.length > 0 &&
            merge_array_values?.map((value, index) => {
              return (
                <div key={index}>
                  <button
                    id="blue"
                    style={{ width: 10, height: 10 }}
                    className="multi_check margin_bottom side"
                    value={value}
                    onClick={(params) => {
                      this.checkingInsideValueArrayOrOtherArray(value);
                    }}
                  />
                  <label className="side" style={{ fontSize: 10 }}>
                    {value}
                  </label>
                </div>
              );
            })}
        </section>
        {modalChecklist}
      </div>
    );
  }
}
