import axios from "axios";
import { snackbar } from "./snackbarActions";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : generate_ai_hbu_summary
 * @endpoint : /sini_v2/ai_excel/generate_ai_hbu_summary
 * @methode : post
 * @params : -
 * @body : worksheet, geo_layer_id, feature_key, field_key, json_ai
 */
export const generate_ai_hbu_summary = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "generate_ai_hbu_summary",
    });

    const { sini_object } = body;

    /*START*/
    let json_ai = {};
    const add_properties = (key, object) => {
      if (object?._id) json_ai[key] = { properties: object.properties || {} };
    };
    const add_default = (key, object, status) => {
      json_ai[key] = {
        properties: object?._id ? object.properties || {} : { status },
      };
    };

    const demography_one = {
      properties: {
        "JUMLAH PENDUDUK":
          sini_object?.demography_one?.properties?.["JUMLAH PENDUDUK"],
      },
    };

    add_properties("demography_one", demography_one);
    add_properties("people_spending", sini_object?.people_spending);
    add_properties("landzone_one", sini_object?.landzone_one);
    add_default(
      "flood_one",
      sini_object?.flood_one,
      "Tidak ada data kerawanan banjir"
    );
    add_default(
      "landslide_one",
      sini_object?.landslide_one,
      "Tidak ada data kerawanan longsor"
    );
    add_default(
      "tsunami_one",
      sini_object?.tsunami_one,
      "Tidak ada data kerawanan tsunami"
    );

    if (sini_object?.rdtr_one?._id) {
      let rdtr_one = structuredClone(sini_object?.rdtr_one);
      const fields = [
        "OBJECTID",
        "NAMOBJ",
        "WADMPR",
        "WADMKK",
        "WADMKC",
        "WADMKD",
        "LUASHA",
        "KDB",
        "KLB",
        "KTB",
        "KDH",
        "KODSZNTEXT",
        "NAMZON_1",
      ];
      const properties = {};
      for (const field of fields) {
        properties[field] = rdtr_one?.properties?.[field] || "";
      }
      rdtr_one.properties = properties;
      add_properties("rdtr_one", rdtr_one);
    }

    const map_array = (key, array) => {
      json_ai[key] =
        array?.length > 0
          ? array.map((item) => ({ properties: item?.properties || {} }))
          : [];
    };
    add_properties("landvalue_one", sini_object?.landvalue_one);
    map_array("landvalue_neighbor", sini_object?.landvalue_neighbor);
    json_ai.poi_features = [];
    json_ai.poi_resume_1 = sini_object?.poi_resume_1 || [];
    json_ai.poi_resume_2 = sini_object?.poi_resume_2 || [];
    // json_ai.poi_resume_3 = sini_object?.poi_resume_3 || [];
    if (sini_object?.poi_survey_1_features?.length > 0) {
      const {
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
        manifest_survey_1,
      } = sini_object;
      let { poi_survey_1_features } = sini_object;

      //pake fields.name
      const fields_used = [
        "DATA LINGKUNGAN",
        "Karakteristik Lokasi",
        "Karakteristik Kepadatan Pengembangan",
        "Karakteristik Pertumbuhan",
        "Issue Lingkungan",
        "Catatan Issue Lingkungan",
        "Analisis Risiko",
        "Catatan Analisis Risiko",
        "DATA PROPERTI",
        "Tipe Properti",
        "Aksesibilitas",
        "Data Jalan: Jumlah Jalur",
        "Data Jalan: Lebar Jalan",
        "Data Jalan: Jumlah Lajur",
        "Data Jalan: Material",
        "Data Jalan: Drainase",
        "Data Jalan: Penerangan",
        "Data Jalan: Kondisi Fisik",
        "Data Jalan: Intensitas Lalulintas",
        "Status Hunian",
      ];
      const fields = manifest_survey_1?.fields || [];
      poi_survey_1_features = poi_survey_1_features.map((feature) => {
        let properties = {};
        fields_used.forEach((field_name) => {
          const field_key = fields.find(
            (field) => field.name === field_name
          )?.key;
          const value = feature?.properties?.[field_key];
          properties[field_name] = value;
        });
        return properties;
      });
      json_ai = {
        ...json_ai,
        poi_survey_1_features,
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
      };
    }

    body.json_ai = json_ai;
    delete body.sini_object;
    /*FINISH*/

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
      timeout: 25000,
    };
    const res = await axios.post(
      bun_server + `/sini_v2/ai_excel/generate_ai_hbu_summary`,
      body,
      config
    );

    // dispatch({
    //   type: "set_value_sini",
    //   payload: {
    //     key: "ai_hbu_summary_object",
    //     value: res.data,
    //   },
    // });

    console.log("json_ai", json_ai);

    dispatch({
      type: "clear_loading_action",
      payload: "generate_ai_hbu_summary",
    });

    dispatch(
      snackbar({
        is_open: true,
        status: "success",
        message: "Generate Summary HBU Berhasil",
      })
    );
  } catch (error) {
    let message = error.message;
    if (error.code === "ECONNABORTED") {
      message = "Timeout";
    } else if (
      error?.response?.data &&
      typeof error?.response?.data === "string"
    ) {
      message = error?.response?.data;
    } else if (error.message) {
      message = error.message;
    }

    dispatch(
      snackbar({
        is_open: true,
        status: "info",
        message: "Tunggu Sebentar...",
      })
    );

    dispatch({
      type: "clear_loading_action",
      payload: "generate_ai_hbu_summary",
    });

    if (message === "Timeout") {
      return message;
    }
  }
};
