import React, { createRef } from 'react'
import MARKDOWN_RENDERER from '../sini_v2_full/MARKDOWN_RENDERER';
import sort_array from '../../App/validation/sort_array';
import st_colors from "../../Data/st_colors.json";
import BarDiagram from './BarDiagram';

export default class TemplatePrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.imgRef = createRef(null)
  }

  componentDidMount = () => {
    // const { map_object_excel } = this.props;
    // const canvas = map_object_excel?.getCanvas();
    // const imgData = canvas.toDataURL("image/png");
    // this.imgRef.current.src = imgData;


    // // Simpan ukuran asli canvas
    // const originalSize = {
    //   width: map_object_excel.getCanvas().width,
    //   height: map_object_excel.getCanvas().height,
    // };

    // // Perbesar canvas sementara
    // map_object_excel.getCanvas().width = 1920; // Lebar lebih besar
    // map_object_excel.getCanvas().height = 1080; // Tinggi lebih besar
    // map_object_excel.resize();

    // // Ambil gambar dari canvas
    // setTimeout(() => {
    //   const imgData = map_object_excel.getCanvas().toDataURL("image/png");
    //   this.imgRef.current.src = imgData; // Masukkan ke elemen <img>

    //   // Kembalikan ukuran asli
    //   map_object_excel.getCanvas().width = originalSize.width;
    //   map_object_excel.getCanvas().height = originalSize.height;
    //   map_object_excel.resize();
    // }, 1000); // Beri waktu render
  }

  // componentDidUpdate = (prevProps) => {
  //   if (prevProps?.is_print !== this.props.is_print) {
  //     const { map_object_excel } = this.props;
  //     const canvas = map_object_excel?.getCanvas();
  //     const imgData = canvas.toDataURL("image/png");
  //     this.imgRef.current.src = imgData;

  //     console.log(imgData)
  //   }
  // }

  render() {

    const { summary_result } = this.props
    const hbu_best_alternative = summary_result?.hbu_best_alternative || "";
    const hbu_best_alternative_reason =
      summary_result?.hbu_best_alternative_reason || "";
    const hbu_best_alternative_notes =
      summary_result?.hbu_best_alternative_notes || "";
    const hbu_alternative_array = summary_result?.hbu_alternative_array || [];

    const {
      data_object_hbu = "",
      perhitungan_sesuai_tata_ruang_tanah_kosong = "",
      perhitungan_sesuai_tata_ruang_bangunan = "",
      uji_maximum_productivity = "",
      uji_fisik_hbu_tanah_kosong = "",
      uji_fisik_hbu_bangunan_existing = "",
      alasan_dipilihnya_ranking_1 = "",
    } = summary_result || {};
    const summary_sini = summary_result?.summary_sini || "";
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = this.props.properties;

    // Map colors using the modulo operation
    const backgroundColors = hbu_alternative_array.map(
      (_, index) => st_colors[index % st_colors.length]
    );

    // const hoverBackgroundColors = backgroundColors.map(
    //   (color) => color.replace("1)", "0.8)") // Adjust for transparency if the color is rgba, otherwise leave as-is.
    // );

    // const chart_data = {
    //   labels: hbu_alternative_array.map((item) => item.name),
    //   datasets: [
    //     {
    //       label: "Result Scores",
    //       data: hbu_alternative_array.map((item) => item.result_score),
    //       backgroundColor: backgroundColors,
    //       hoverBackgroundColor: hoverBackgroundColors,
    //     },
    //   ],
    // };

    const chart_data = hbu_alternative_array.map((item) => {
      return {
        label: item.name,
        value: item.result_score
      }
    })

    return (
      <div className='hide_component'>
        <section className='padding_20 flex flex_col gap_10'>
          <h1 className="margin_bottom">HBU SUMMARY</h1>
          <aside className='grid grid_2 gap_10'>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{data_object_hbu}</p>
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Provinsi</label>
                <p className="text_bold">{PROVINSI}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kabupaten atau Kota</label>
                <p className="text_bold">{KABKOT}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kecamatan</label>
                <p className="text_bold">{KECAMATAN}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Desa atau Kelurahan</label>
                <p className="text_bold">{DESA}</p>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Rangkuman kalkulasi HBU
              </h1>
              <div className='h_200 center_perfect'>
                {/* <RANGKING_CHART hbu_alternative_array={hbu_alternative_array} /> */}
                {/* <Chart type="bar" data={chart_data}/> */}
                <BarDiagram data={chart_data} width={600} height={120} colors={backgroundColors} />
              </div>

              <div className="container_light background_white">
                <label className="text_medium margin_bottom margin_right">
                  Alternatif HBU terbaik
                </label>
                <h1 className="badge_pill_big text_bold background_black">{hbu_best_alternative}</h1>
              </div>
            </div>

            <div className="child_tile span_2 container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">Uji Legal HBU</h1>

              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Tanah Kosong)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_tanah_kosong}</p>
              <br />
              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Bangunan)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_bangunan}</p>
            </div>
            <div className="child_tile span_2 container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Maximum Productivity
              </h1>
              <p>{uji_maximum_productivity}</p>
            </div>
            {/* </section> */}

            {/* <section className="border"> */}
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU (Tanah Kosong)
              </h1>
              <p>{uji_fisik_hbu_tanah_kosong}</p>
            </div>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU Bangunan (Existing)
              </h1>
              <p>{uji_fisik_hbu_bangunan_existing}</p>
            </div>
            {/* </section> */}
          </aside>

          <div
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr",
            }}
          >
            <div className="break_before_pdf child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold">
                Rangkuman finansial setiap alternatif
              </h1>

              <table className="table scroll_auto">
                <thead>
                  <tr className="font_10">
                    <th></th>
                    <th>CAPEX</th>
                    <th>NPV</th>
                    <th>IRR</th>
                    <th>PI</th>
                    <th>PP</th>
                    <th>DR</th>
                    {/* <th>Raw score</th> */}
                    <th>Result score</th>
                    <th>Keterangan</th>
                    <th>Indikator Kelayakan</th>
                    <th>Ranking</th>
                  </tr>
                </thead>
                <tbody>
                  {sort_array(hbu_alternative_array, "result_score", false).map((item, idx) => {
                    const {
                      name,
                      npv,
                      irr,
                      pi,
                      capex,
                      payback_periode_year,
                      // raw_score,
                      result_score,
                      reasoning_behind_the_result_score,
                      discount_rate,
                      indikator_kelayakan
                      // rangking,
                    } = item;

                    const to_id_format = (num_val) => {
                      return new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(num_val));
                    };

                    return (
                      <tr key={idx} className="font_10">
                        <td>{name}</td>
                        <td>{to_id_format(capex)}</td>
                        <td>{to_id_format(npv)}</td>
                        <td>{to_id_format(irr?.toFixed(2))}</td>
                        <td>{to_id_format(pi)}</td>
                        <td>
                          {to_id_format(payback_periode_year?.toFixed(1))}
                        </td>
                        <td>{discount_rate}</td>
                        {/* <td>{to_id_format(raw_score)}</td> */}
                        <td>{to_id_format(result_score)}</td>
                        <td>{reasoning_behind_the_result_score}</td>
                        <td>{indikator_kelayakan}</td>
                        <td>{to_id_format(idx + 1)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="h_full">
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Dipilihnya Alternatif Ranking 1
                </h1>
                <p className="text_medium">{alasan_dipilihnya_ranking_1}</p>
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Pemilihan Alternatif
                </h1>
                <p className="text_medium">
                  <MARKDOWN_RENDERER markdownContent={hbu_best_alternative_reason} />
                </p>
                <br />
                <h1 className="text_bold margin_bottom">Catatan</h1>
                <p className="text_medium">{hbu_best_alternative_notes}</p>
              </div>
            </div>
          </div>

          {/* <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU (Tanah Kosong)
              </h1>
              <p>{uji_fisik_hbu_tanah_kosong}</p>
            </div> */}

          {/* <div style={{ height: "300px" }}>
            <img
              ref={this.imgRef}
              alt="Peta Screenshot"
            />
          </div> */}
          <div
            className="break_before_pdf child_tile container_light background_grey_light outline_transparent"
            style={{ height: "50%" }}
          >
            {summary_result && <div style={{ height: "100%" }}>
              <div>
                <h1 className="bold">Summary SINI</h1>
                <hr />
                <MARKDOWN_RENDERER markdownContent={summary_sini} />
              </div>
            </div>}
          </div>
        </section>
      </div>
    )
  }
}
