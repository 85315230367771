/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE*/
import icon_expand_bottom from "../../Assets/svg/icon_expand_bottom.svg";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class SINI_LAND extends Component {
  state = {};

  componentDidMount() {
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landzone_one = sini_object?.landzone_one;
    if (landvalue_one !== undefined || landzone_one !== undefined) {
      this.add_slide_in_class();
    }
  }

  componentDidUpdate(prevProps) {
    const loading_item_array_before =
      prevProps.loading_reducer.loading_item_array;
    const loading_item_array_after =
      this.props.loading_reducer.loading_item_array;
    const last_loading =
      loading_item_array_after[loading_item_array_after.length - 1];
    if (
      loading_item_array_after.length !== loading_item_array_before.length &&
      (last_loading === "landvalue" || last_loading === "get_ai_parent_object")
    ) {
      this.add_slide_in_class();
    }
  }

  add_slide_in_class = () => {
    const element = document.querySelector("#landvalue_parent");
    if (element) {
      element.classList.add("slide-in");
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "landvalue",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: [
        "landvalue",
        "landzone",
        "landvalue_neighbor",
        "landzone_neighbor",
        "poi_survey_1_features",
      ],
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_landvalue = loading_item_array.includes("landvalue");
    const is_loading_landzone = loading_item_array.includes("landzone");
    const is_loading_property = loading_item_array.includes("poi_property");

    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );

    const landvalue_one = sini_object?.landvalue_one;
    const landzone_one = sini_object?.landzone_one;
    const poi_property = sini_object?.poi_property;
    const average_property_rumah_rp = sini_object?.average_property_rumah_rp;
    const poi_survey_1_features = sini_object?.poi_survey_1_features || [];
    const average_sewa = sini_object?.average_sewa || 0;

    let content;

    if (
      is_loading_landvalue ||
      landvalue_one !== undefined ||
      landzone_one !== undefined ||
      poi_survey_1_features.length > 0
    ) {
      const landvalue_value = landvalue_one?.properties?.["HARGA"];
      const landzone_value = landzone_one?.properties?.["GUNATANAH"];

      const is_landvalue_empty = landvalue_value === undefined;
      const is_landzone_empty =
        !landzone_value ||
        landzone_value === undefined ||
        landzone_value === "undefined" ||
        landzone_value === "0" ||
        landzone_value === 0;
      const is_poi_property_empty =
        poi_property === undefined || poi_property?.length === 0;

      //PROPERTY RUMAH
      let property_content;
      if (is_loading_landzone || is_loading_landvalue || is_loading_property) {
        property_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else if (!is_poi_property_empty) {
        property_content = (
          <main className="margin_bottom">
            <section className="margin_bottom">
              <p className="text_small">Rata-rata harga properti</p>
              <h1 className="text_bold">
                Rp.
                {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  average_property_rumah_rp
                )}
              </h1>
            </section>
          </main>
        );
      } else {
        property_content = <></>;
      }

      //LANDVALUE
      let landvalue_content;
      if (is_loading_landvalue) {
        landvalue_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else if (!is_landvalue_empty) {
        landvalue_content = (
          <main>
            {poi_survey_1_features.length > 0 && (
              <section className="margin_bottom">
                <p className="text_small">
                  Rata-rata harga sewa berdasarkan hasil survei
                </p>
                <div className="text_bold">
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    average_sewa
                  )}{" "}
                  /tahun{" "}
                  <span className="badge_small background_orange">Baru</span>
                </div>
              </section>
            )}
            <section className="margin_bottom">
              <p className="text_small">Perkiraan harga tanah</p>
              <h1 className="text_bold margin_bottom">
                Rp
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(parseInt(landvalue_value))}{" "}
                <small>{dict?.["per square meter"]?.[language]}</small>
              </h1>
              {property_content}
            </section>
          </main>
        );
      } else {
        landvalue_content = <></>;
      }

      //LANDZONE
      let landzone_content;
      if (is_loading_landzone || is_loading_landvalue) {
        landzone_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else if (!is_landzone_empty) {
        landzone_content = (
          <section className="margin_bottom">
            <p className="text_small">Perkiraan guna tanah</p>
            <h1 className="text_bold">{landzone_value}</h1>
          </section>
        );
      } else {
        landzone_content = <></>;
      }

      content = (
        <main className="container_light background_white margin_bottom outline_green">
          <div
            className="badge background_green margin_bottom"
            onClick={this.on_set_sini_menu_active}
          >
            Guna dan perkiraan nilai tanah
          </div>
          <br />
          {landvalue_content}

          {landzone_content}
          <div className="text_center">
            <img
              onClick={this.on_set_sini_menu_active}
              alt="expand"
              src={icon_expand_bottom}
              width={45}
              className="cursor_pointer"
              data-mapid="clickOpenLand"
            />
          </div>
        </main>
      );
    }
    return <main id="landvalue_parent">{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_LAND);
