/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import M_S_RDTR_ONE from "../libre_marker_sini/M_S_RDTR_ONE";

/*REDUX*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE*/

/*FUNCTION*/
import calculate_rdtr from "../../App/validation/calculate_rdtr";

/*DATA*/
import colors_layer_rdtr from "../../Data/colors_layer_rdtr.json";

/*CONST*/

class LAYER_S_RDTR_ONE extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      // this.on_render_layer();

      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      is_sini_visible,
    } = this.props.sini;
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const visibility =
      sini_map_show.includes("rdtr_one") && is_sini_visible
        ? "visible"
        : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const rdtr_one = item?.rdtr_one || [];
        const geojson_rdtr_one = {
          type: "FeatureCollection",
          features: rdtr_one ? [rdtr_one] : [],
        };

        const layer_id_fill = `geojson_rdtr_one_${request_id}`;
        const layer_id_line = `geojson_rdtr_one_line_${request_id}`;

        if (!map_object.getSource(layer_id_fill)) {
          map_object.addSource(layer_id_fill, {
            type: "geojson",
            data: geojson_rdtr_one,
          });
        } else {
          map_object.getSource(layer_id_fill).setData(geojson_rdtr_one);
        }

        if (!map_object.getLayer(layer_id_fill)) {
          map_object.addLayer({
            id: layer_id_fill,
            source: layer_id_fill,
            type: "fill",
            paint: {
              "fill-color": colors_layer_rdtr,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_fill, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_fill,
            "fill-opacity",
            Number(sini_polygon_fill_opacity)
          );
        }

        if (!map_object.getLayer(layer_id_line)) {
          map_object.addLayer({
            id: layer_id_line,
            source: layer_id_fill,
            type: "line",
            paint: {
              "line-color": colors_layer_rdtr,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_line, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_line,
            "line-width",
            Number(sini_polygon_line_width)
          );
        }

        // handle event on click on map
        this.on_handle_click(layer_id_fill);
      });
    }
  };

  on_handle_click = (layer_id_fill) => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    // First, remove any existing handlers to avoid duplication
    map_object.off("click", layer_id_fill, this.on_click_rdtr); // Remove existing click event listener
    map_object.off("touchstart", layer_id_fill, this.on_click_rdtr); // Remove existing touchstart event listener

    // Then, attach click and touchstart events
    map_object.on("click", layer_id_fill, this.on_click_rdtr); // For desktop
    map_object.on("touchstart", layer_id_fill, this.on_click_rdtr); // For touchscreen devices
  };

  on_click_rdtr = (event) => {
    const { rdtr_calc_mode, rdtr_input_area_m2 } = this.props.sini;
    const long = event?.lngLat?.lng || 0;
    const lat = event?.lngLat?.lat || 0;
    const properties = event?.features?.[0]?.properties || {};
    const { OBJECTID, NAMOBJ, LUASHA } = properties;

    this.props.set_value_sini({
      key: "rdtr_input_OBJECTID",
      value: OBJECTID,
    });
    this.props.set_value_sini({
      key: "rdtr_input_long",
      value: long,
    });
    this.props.set_value_sini({
      key: "rdtr_input_lat",
      value: lat,
    });
    this.props.set_value_sini({
      key: "rdtr_input_NAMOBJ",
      value: NAMOBJ,
    });
    this.props.set_value_sini({
      key: "rdtr_input_LUASHA",
      value: LUASHA,
    });

    if (rdtr_calc_mode === "one_mode") {
      let { KDB, KLB, KDH } = properties;
      [KDB, KLB, KDH] = [KDB, KLB, KDH].map((value) =>
        isNaN(Number(value)) ? 0 : Number(value)
      );
      this.props.set_value_sini({
        key: "rdtr_input_KDB",
        value: KDB,
      });
      this.props.set_value_sini({
        key: "rdtr_input_KLB",
        value: KLB,
      });
      this.props.set_value_sini({
        key: "rdtr_input_KDH",
        value: KDH,
      });

      const result = calculate_rdtr({ KDB, KLB, KDH, rdtr_input_area_m2 });
      const {
        rdtr_result_LDB,
        rdtr_result_LLB,
        rdtr_result_LRH,
        rdtr_result_JLB,
      } = result;

      this.props.set_value_sini({
        key: "rdtr_result_LDB",
        value: rdtr_result_LDB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LLB",
        value: rdtr_result_LLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_JLB",
        value: rdtr_result_JLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LRH",
        value: rdtr_result_LRH,
      });
    }
  };

  render() {
    return (
      <main>
        <M_S_RDTR_ONE />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_sini })(LAYER_S_RDTR_ONE);
