/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import UJI_LUAS from "./UJI_LUAS";
import UJI_RASIO from "./UJI_RASIO";
import UJI_FINANSIAL from "./UJI_FINANSIAL";

/*REDUX*/
import { set_value_hbu } from "../../App/actions/hbu_actions";
import { calc_fs } from "../../App/actions/calc_fs";

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";

/*FUNCTION*/

/*DATA*/
import PEDOMAN_HBU from "../../Data/PEDOMAN_HBU.csv";

/*CONST*/

class HBU_MANUAL extends Component {
  state = {
    modal_show_all_alt: false,
    modal_uji_luas: false,
    modal_uji_rasio: false,
  };

  componentDidMount() {
    this.init_csv();
    this.generate_init_data();
  }

  toggle_uji_rasio = () => {
    this.setState({
      modal_uji_rasio: !this.state.modal_uji_rasio,
    });
  };

  toggle_uji_luas = () => {
    this.setState({
      modal_uji_luas: !this.state.modal_uji_luas,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_run = () => {
    console.log("on_run");
    const { rows, luas_tanah, ratio_tanah, percent_ratio_tolerance } =
      this.props.hbu_reducer;
    console.log("rows.length=", rows.length);

    //seleksi 1    //"MIN_LUAS", "MAX_LUAS",
    const rows_filtered_1 = rows.filter(
      (item) =>
        luas_tanah >= parseFloat(item.MIN_LUAS) &&
        luas_tanah <= parseFloat(item.MAX_LUAS)
    );
    console.log("rows_filtered_1.length=", rows_filtered_1.length);

    const rows_rejected_1 = rows.filter(
      (item) =>
        !(
          luas_tanah >= parseFloat(item.MIN_LUAS) &&
          luas_tanah <= parseFloat(item.MAX_LUAS)
        )
    );
    console.log("rows_rejected_1.length=", rows_rejected_1.length);

    this.props.set_value_hbu({
      key: "rows_filtered_1",
      value: rows_filtered_1,
    });
    this.props.set_value_hbu({
      key: "rows_rejected_1",
      value: rows_rejected_1,
    });

    //seleksi 2    //"MIN_RASIO", "MAX_RASIO",
    const min_dec = 1 - parseFloat(percent_ratio_tolerance) / 100;
    const max_dec = 1 + parseFloat(percent_ratio_tolerance) / 100;
    const rows_filtered_2 = rows_filtered_1.filter(
      (item) =>
        ratio_tanah >= parseFloat(item.MIN_RASIO * min_dec) &&
        ratio_tanah <= parseFloat(item.MAX_RASIO * max_dec)
    );
    console.log("rows_filtered_2.length=", rows_filtered_2.length);

    const rows_rejected_2 = rows_filtered_1.filter(
      (item) =>
        !(
          ratio_tanah >= parseFloat(item.MIN_RASIO * min_dec) &&
          ratio_tanah <= parseFloat(item.MAX_RASIO * max_dec)
        )
    );
    console.log("rows_rejected_2.length=", rows_rejected_2.length);

    this.props.set_value_hbu({
      key: "rows_filtered_2",
      value: rows_filtered_2,
    });

    this.props.set_value_hbu({
      key: "rows_rejected_2",
      value: rows_rejected_2,
    });

    const rows_filtered_3 = rows_filtered_2;
    const rows_rejected_3 = [];

    this.props.set_value_hbu({
      key: "rows_filtered_3",
      value: rows_filtered_3,
    });

    this.props.set_value_hbu({
      key: "rows_rejected_3",
      value: rows_rejected_3,
    });
  };

  generate_init_data = () => {
    const { worksheet } = this.props;
    const first_year = worksheet?.[1]?.value?.find(
      (item) => item?.p === "D18"
    )?.v;
    const luas_tanah = worksheet?.[1]?.value?.find(
      (item) => item?.p === "D12"
    )?.v;
    const luas_bangunan = worksheet?.[1]?.value?.find(
      (item) => item?.p === "D24"
    )?.v;
    const panjang_tanah = worksheet?.[1]?.value?.find(
      (item) => item?.p === "D14"
    )?.v;
    const lebar_tanah = worksheet?.[1]?.value?.find(
      (item) => item?.p === "D16"
    )?.v;
    const ratio_tanah = (panjang_tanah / lebar_tanah).toFixed(1);
    this.props.set_value_hbu({
      key: "first_year",
      value: first_year,
    });
    this.props.set_value_hbu({
      key: "luas_tanah",
      value: luas_tanah,
    });
    this.props.set_value_hbu({
      key: "luas_bangunan",
      value: luas_bangunan,
    });
    this.props.set_value_hbu({
      key: "panjang_tanah",
      value: panjang_tanah,
    });
    this.props.set_value_hbu({
      key: "lebar_tanah",
      value: lebar_tanah,
    });
    this.props.set_value_hbu({
      key: "ratio_tanah",
      value: ratio_tanah,
    });
  };

  init_csv = async () => {
    try {
      const response = await fetch(PEDOMAN_HBU);
      const text = await response.text();
      const rows = text
        .split("\n")
        .map((row) => row.trim())
        .filter((row) => row);
      if (rows.length < 2) return;
      const headers = rows[0].split(";").map((header) => header.trim());
      const jsonData = rows.slice(1).map((row) => {
        const values = row.split(";").map((value) => value.trim());
        return headers.reduce((acc, header, index) => {
          acc[header] = values[index] || "";
          return acc;
        }, {});
      });
      this.props.set_value_hbu({
        key: "headers",
        value: headers,
      });
      this.props.set_value_hbu({
        key: "rows",
        value: jsonData,
      });
    } catch (error) {}
  };

  toggle_show_all_alt = () => {
    this.setState({ modal_show_all_alt: !this.state.modal_show_all_alt });
  };

  render() {
    //local storage

    //local state
    const { modal_show_all_alt, modal_uji_luas, modal_uji_rasio } = this.state;

    //global props
    const {
      headers,
      rows,
      luas_tanah,
      luas_bangunan,
      panjang_tanah,
      lebar_tanah,
      ratio_tanah,
      rows_filtered_1,
      rows_filtered_2,
      rows_filtered_3,

      rows_rejected_1,
      rows_rejected_2,
      rows_rejected_3,

      // percent_ratio_tolerance,
    } = this.props.hbu_reducer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    //content
    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];

    const modal_uji_rasio_content = modal_uji_rasio && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_rasio}
        onClose={this.toggle_uji_rasio}
      >
        <div className="box-body" id="box-body">
          <UJI_RASIO />
        </div>
      </Modal>
    );

    const modal_uji_luas_content = modal_uji_luas && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_luas}
        onClose={this.toggle_uji_luas}
      >
        <div className="box-body" id="box-body">
          <UJI_LUAS />
        </div>
      </Modal>
    );

    const modal_show_all_alt_content = modal_show_all_alt && (
      <Modal
        modalSize="large"
        isOpen={modal_show_all_alt}
        onClose={this.toggle_show_all_alt}
      >
        <div className="box-body" id="box-body">
          <section style={{ overflowX: "auto" }}>
            <table className="table scroll_auto">
              <thead>
                <tr>
                  <td>No</td>
                  {headers.map((header, index) => (
                    <td key={index}>{header}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {headers.map((header, index) => (
                      <td key={index}>{item[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </Modal>
    );

    return (
      <main
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          overflowY: "auto",
          height: "100%",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {modal_show_all_alt_content}
        {modal_uji_luas_content}
        {modal_uji_rasio_content}
        <table className="table margin_bottom">
          <tbody>
            <tr>
              <td>Luas tanah</td>
              <td>{luas_tanah}</td>
            </tr>
            <tr>
              <td>Luas bangunan</td>
              <td>{luas_bangunan}</td>
            </tr>
            <tr>
              <td>Panjang tanah</td>
              <td>{panjang_tanah}</td>
            </tr>
            <tr>
              <td>Lebar tanah</td>
              <td>{lebar_tanah}</td>
            </tr>
            <tr>
              <td>Rasion panjang/lebar</td>
              <td>{ratio_tanah}</td>
            </tr>
            <tr>
              <td>Landvalue SINI</td>
              <td>{landvalue_value}</td>
            </tr>
          </tbody>
        </table>

        <button
          className="button background_black margin_bottom margin_right"
          onClick={this.toggle_show_all_alt}
        >
          Buka pedoman
        </button>
        <button
          className="button background_black margin_bottom margin_right"
          onClick={this.on_run}
        >
          Run
        </button>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Luas</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_1.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_1.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas}
                />
              </div>
              <p className="text_medium">
                Uji luas objek dalam penentuan Highest and Best Use (HBU)
                bertujuan memastikan lahan sesuai dengan regulasi dan mendukung
                pengembangan yang paling menguntungkan. Proses ini membantu
                mengevaluasi kelayakan investasi, mengoptimalkan pemanfaatan
                sumber daya, serta meningkatkan nilai properti dengan memilih
                alternatif penggunaan terbaik berdasarkan aspek fisik, ekonomi,
                dan hukum.
              </p>
            </article>
          </div>
        </section>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Rasio dimensi</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_2.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_2.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_rasio}
                />
              </div>
              <p className="text_medium margin_bottom">
                Uji rasio dimensi, seperti 30x30 meter (persegi) atau 30x20
                meter (persegi panjang), dalam penentuan Highest and Best Use
                (HBU) perlu dilakukan untuk memastikan efisiensi tata letak dan
                optimalisasi pemanfaatan lahan. Rasio ini berpengaruh terhadap
                desain bangunan, aksesibilitas, serta kepatuhan terhadap
                regulasi zonasi dan tata ruang. Selain itu, pemilihan dimensi
                yang tepat membantu meningkatkan nilai ekonomi properti dengan
                menyesuaikan bentuk lahan terhadap kebutuhan pasar dan jenis
                penggunaan terbaiknya, seperti komersial, residensial, atau
                industri.
              </p>
            </article>
          </div>
        </section>

        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">
            Uji Kecocokan supply dan demand
          </div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_3.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_3.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                />
              </div>
              <p className="text_medium">...</p>
            </article>
          </div>
        </section>

        <UJI_FINANSIAL />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, { set_value_hbu, calc_fs })(HBU_MANUAL);
