/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import M_S_TSUNAMI_ONE from "../libre_marker_sini/M_S_TSUNAMI_ONE";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import colors_layer_flood from "../../Data/colors_layer_flood.json";

/*CONST*/

class LAYER_S_TSUNAMI extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      // this.on_render_layer();

      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      is_sini_visible,
    } = this.props.sini;
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const visibility =
      sini_map_show.includes("tsunami") && is_sini_visible ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const tsunami_one = item?.tsunami_one || [];
        const geojson_tsunami_one = {
          type: "FeatureCollection",
          features: [tsunami_one],
        };

        const layer_id_fill = `geojson_tsunami_one_${request_id}`;
        const layer_id_line = `geojson_tsunami_one_line_${request_id}`;

        if (!map_object.getSource(layer_id_fill)) {
          map_object.addSource(layer_id_fill, {
            type: "geojson",
            data: geojson_tsunami_one,
          });
        } else {
          map_object.getSource(layer_id_fill).setData(geojson_tsunami_one);
        }

        if (!map_object.getLayer(layer_id_fill)) {
          map_object.addLayer({
            id: layer_id_fill,
            source: layer_id_fill,
            type: "fill",
            paint: {
              "fill-color": colors_layer_flood,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_fill, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_fill,
            "fill-opacity",
            Number(sini_polygon_fill_opacity)
          );
        }

        if (!map_object.getLayer(layer_id_line)) {
          map_object.addLayer({
            id: layer_id_line,
            source: layer_id_fill,
            type: "line",
            paint: {
              "line-color": colors_layer_flood,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_line, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_line,
            "line-width",
            Number(sini_polygon_line_width)
          );
        }
      });
    }
  };

  render() {
    return (
      <main>
        <M_S_TSUNAMI_ONE />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_TSUNAMI);
