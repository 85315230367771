/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/
import { generatePopupContentDOM } from "../libre_popup/popup_geo";

/*DATA*/

/*CONST*/

class LAYER_S_POI_SURVEY_1 extends Component {
  state = {
    popup_handlers: {},
  };

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }

      // this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const id_data = "poi_survey_1_features";
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      is_sini_visible,
    } = this.props.sini;
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const visibility =
      sini_map_show.includes(id_data) && is_sini_visible ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const features = item?.[id_data] || [];

        const geojson = {
          type: "FeatureCollection",
          features: features,
        };
        const paint_object = {
          "circle-color": "#f38026",
          "circle-radius": 5,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#2d2a70",
        };

        const layer_id = `${id_data}_${request_id}`;
        if (!map_object.getSource(layer_id)) {
          map_object.addSource(layer_id, {
            type: "geojson",
            data: geojson,
          });
        } else {
          map_object.getSource(layer_id).setData(geojson);
        }
        if (!map_object.getLayer(layer_id)) {
          map_object.addLayer({
            id: layer_id,
            source: layer_id,
            type: "circle",
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id, "visibility", visibility);
          for (const property in paint_object) {
            map_object.setPaintProperty(
              layer_id,
              property,
              paint_object[property]
            );
          }
        }

        //generate pop up
        const { manifest_survey_1 } = item;
        const fields = manifest_survey_1?.fields || [];
        this.generate_pop_up(layer_id, fields);
      });
    }
  };

  generate_pop_up = (_id, fields) => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (map_object) {
      // Check if the handler is already stored, remove it
      if (this?.state?.popup_handlers?.[_id]) {
        map_object.off("click", _id, this.state.popup_handlers[_id]);
        map_object.off("touchstart", _id, this.state.popup_handlers[_id]); // Remove touchstart handler too
      }

      // Create a new handler
      const click_handler = (event) => {
        const feature = event?.features?.[0];
        if (feature) {
          const properties = feature?.properties || {};
          const geometry = feature?.geometry;
          const type = geometry?.type;
          let longitude, latitude;

          if (type === "Point") {
            longitude = geometry?.coordinates?.[0];
            latitude = geometry?.coordinates?.[1];
          }

          const { container, close_button } = generatePopupContentDOM(
            properties,
            fields
          );
          const popup = new maplibregl.Popup({ closeButton: false }) // Disable maplibre's default close button
            .setLngLat([longitude, latitude])
            .setDOMContent(container)
            .addTo(map_object);

          // Add a listener for the custom close button
          close_button.addEventListener("click", () => {
            popup.remove(); // Manually close the popup when the button is clicked
          });

          const popup_content = container.parentElement; // Access the popup content div
          popup_content.style.borderRadius = "15px";
          popup_content.style.margin = "0";
          popup_content.style.padding = "10px";
          popup_content.style.overflow = "hidden";
        }
      };

      // Save the handler in state
      this.setState((prevState) => ({
        popup_handlers: { ...prevState.popup_handlers, [_id]: click_handler },
      }));

      // Attach click and touchstart events
      map_object.on("click", _id, click_handler); // For desktop
      map_object.on("touchstart", _id, click_handler); // For touchscreen devices
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_POI_SURVEY_1);
