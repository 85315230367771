/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { set_value_hbu } from "../../App/actions/hbu_actions";
import { calc_fs } from "../../App/actions/calc_fs";

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";
import NPV_DETAIL from "./NPV_DETAIL";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class UJI_FINANSIAL extends Component {
  state = {
    modal_npv_detail: false,
  };

  toggle_npv_detail = (alt_selected) => {
    this.setState({
      modal_npv_detail: !this.state.modal_npv_detail,
    });
    if (alt_selected) {
      this.props.set_value_hbu({
        key: "alt_selected",
        value: alt_selected,
      });
    }
  };

  on_change_props = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.props.set_value_hbu({
      key: name,
      value,
    });
  };

  on_calc_fs = () => {
    let {
      luas_tanah,
      luas_bangunan,
      rows_filtered_3,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      economic_life_years,
      tax_percent,
    } = this.props.hbu_reducer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];

    this.props.calc_fs({
      luas_tanah,
      luas_bangunan,
      rows_filtered_3,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      economic_life_years,
      tax_percent,
      landvalue_value,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_npv_detail } = this.state;

    //global props
    const {
      rows_filtered_3,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
    } = this.props.hbu_reducer;

    //content
    const modal_npv_detail_content = modal_npv_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_npv_detail}
        onClose={this.toggle_npv_detail}
      >
        <div className="box-body" id="box-body">
          <NPV_DETAIL />
        </div>
      </Modal>
    );

    return (
      <section className="container background_grey_light margin_bottom">
        {modal_npv_detail_content}
        <div className="text_bold margin_bottom">Uji Finansial</div>
        <div className="two_container_equal">
          <article className="container background_white margin_bottom">
            <div className="two_container_small_right">
              <h1 className="text_bold margin_bottom">
                Atur parameter dan asumsi
              </h1>
              <img
                className="cursor_pointer text_right"
                alt="expand"
                src={expand}
                style={{ height: "20px" }}
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Durasi Proyek (dalam tahun)</p>
              <input
                name="project_duration_years"
                value={project_duration_years}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Tingkat Diskonto Primer (dalam persen)
              </p>
              <input
                name="discount_rate_1_percent"
                value={discount_rate_1_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">
                Tingkat Diskonto Sekunder (dalam persen)
              </p>
              <input
                name="discount_rate_2_percent"
                value={discount_rate_2_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Growth Pendapatan (dalam persen)</p>
              <input
                name="revenue_growth_percent"
                value={revenue_growth_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Growth OPEX (dalam persen)</p>
              <input
                name="opex_growth_percent"
                value={opex_growth_percent}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <div className="margin_bottom">
              <p className="text_medium">Modal Kerja (Rupiah)</p>
              <input
                name="working_capital_idr"
                value={working_capital_idr}
                onChange={this.on_change_props}
                type="number"
              />
            </div>
            <button
              className="button background_black"
              onClick={this.on_calc_fs}
            >
              Hitung Uji Finansial
            </button>
          </article>
          <article className="container background_white margin_bottom">
            <div className="two_container_small_right">
              <h1 className="text_bold margin_bottom">Penjelasan</h1>
              <img
                className="cursor_pointer text_right"
                alt="expand"
                src={expand}
                style={{ height: "20px" }}
              />
            </div>
            <p className="text_medium">...</p>
          </article>
        </div>
        <aside>
          <table className="table margin_bottom" style={{ width: "100%" }}>
            <thead>
              <tr>
                <td className="text_bold">No</td>
                <td className="text_bold"> Nama Alternatif</td>
                <td>
                  CAPEX Tanah
                  <br />
                  CAPEX Bangunan
                  <br />
                  <br />
                  <b className="text_bold">
                    CAPEX Total <br />
                    (rupiah)
                  </b>
                </td>
                <td className="text_bold">NPV (rupiah)</td>
                <td className="text_bold">IRR (persen)</td>
                <td className="text_bold">PI (indeks)</td>
                <td className="text_bold">PP (tahun)</td>
                <td className="text_bold">DR (persen)</td>
              </tr>
            </thead>
            <tbody>
              {rows_filtered_3.map((item, idx) => {
                const { JENIS, capex_tanah, capex_bangunan, capex_total, npv } =
                  item;
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{JENIS}</td>
                    <td>
                      {capex_tanah &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(capex_tanah)}
                      <br />
                      {capex_bangunan &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(capex_bangunan)}
                      <br />
                      <br />
                      <b className="text_bold">
                        {capex_total &&
                          new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(capex_total)}
                      </b>
                    </td>
                    <td className="text_bold">
                      <div className="text_right">
                        <img
                          onClick={this.toggle_npv_detail.bind(this, item)}
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "15px" }}
                        />
                      </div>
                      {capex_tanah &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(npv)}
                    </td>
                    <td className="text_bold">-</td>
                    <td className="text_bold">-</td>
                    <td className="text_bold">-</td>
                    <td className="text_bold">-</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </aside>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, { set_value_hbu, calc_fs })(
  UJI_FINANSIAL
);
