/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import maplibregl from "maplibre-gl";

/*COMPONENT*/

/*REDUX*/
import {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
} from "../../App/actions/sini_v2";

/*PICTURE*/
import ICON_MARKER from "../../Assets/jsx/ICON_MARKER";
import ICON_MARKER_SELECTED from "../../Assets/jsx/ICON_MARKER_SELECTED";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class LAYER_S_CENTER extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      // this.on_render_layer();
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }
    }
  }

  on_render_layer = () => {
    const {
      request_id_loading,
      request_id_active,
      sini_v2_list,
      ai_parent_list,
      is_sini_visible,
    } = this.props.sini;
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (!map_object) return;

    // Unique marker class for this component's markers
    const unique_marker_class = "mapboxgl-marker-s-center";

    if (!is_sini_visible) {
      // Remove only the markers related to this component
      document
        .querySelectorAll(`.${unique_marker_class}`)
        .forEach((marker) => marker.remove());
      return;
    }

    const render_marker = (lat, long, request_id, selected) => {
      const marker_id = `marker_center_${request_id}`;
      let marker_element = document.getElementById(marker_id);
      if (!marker_element) {
        marker_element = document.createElement("div");
      }
      marker_element.id = marker_id;
      marker_element.className = unique_marker_class;
      marker_element.style.position = "absolute";
      marker_element.style.transform = "translate(-50%, -100%)";

      const marker_jsx = selected ? <ICON_MARKER_SELECTED /> : <ICON_MARKER />;
      const marker_html = ReactDOMServer.renderToString(marker_jsx);
      marker_element.innerHTML = marker_html;
      const lngLat = new maplibregl.LngLat(long, lat);
      const point = map_object.project(lngLat);
      marker_element.style.left = `${point.x}px`;
      marker_element.style.top = `${point.y}px`;
      map_object.getContainer().appendChild(marker_element);
      map_object.on("move", () => {
        const newPoint = map_object.project(lngLat);
        marker_element.style.left = `${newPoint.x}px`;
        marker_element.style.top = `${newPoint.y}px`;
      });
      marker_element.addEventListener("click", () => {
        this.on_click_marker(request_id);
      });
    };

    sini_v2_list.forEach((item) => {
      const { request_id, lat, long } = item;
      if (request_id !== request_id_loading) {
        render_marker(lat, long, request_id, request_id === request_id_active);
      }
    });

    ai_parent_list.forEach((item) => {
      const { request_id, lat, long } = item;
      if (request_id !== request_id_loading) {
        render_marker(lat, long, request_id, request_id === request_id_active);
      }
    });
  };

  on_click_marker = (request_id) => {
    this.props.set_value_sini({
      key: "request_id_active",
      value: request_id,
    });

    this.props.fly_init_update();
    const { ai_parent_detail_list, sini_v2_list, ai_parent_list } =
      this.props.sini;

    const sini_db_object = ai_parent_detail_list.find(
      (item) => item.request_id === request_id
    );
    const sini_local = sini_v2_list.find(
      (item) => item.request_id === request_id
    );

    if (!sini_db_object?.request_id && !sini_local?.request_id) {
      const sini_object = ai_parent_list.find(
        (item) => item.request_id === request_id
      );

      const lat = sini_object?.lat;
      const long = sini_object?.long;

      if (lat && long) {
        this.props.set_value_sini({
          key: "request_id_loading",
          value: request_id,
        });
        this.props.set_value_sini({
          key: "sini_marker_active",
          value: { lat, long },
        });
      }

      const params = {
        request_id: request_id,
      };
      this.props.get_ai_parent_object(params);
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
})(LAYER_S_CENTER);
