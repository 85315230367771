import axios from "axios";
import { snackbar } from "./snackbarActions";
// import { timeout } from "workbox-core/_private";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : generate_ai_appraisal_summary
 * @endpoint : /sini_v2/ai_excel/generate_ai_appraisal_summary
 * @methode : post
 * @params : -
 * @body : worksheet, geo_layer_id, feature_key, field_key, json_ai
 */
export const generate_ai_appraisal_summary = (body) => async (dispatch) => {
  try {

    dispatch({
      type: "set_loading_action",
      payload: "generate_ai_appraisal_summary",
    });

    const { sini_object } = body;

    /*START*/
    let json_ai = {};
    // const add_properties = (key, object) => {
    //   if (object?._id) json_ai[key] = { properties: object.properties || {} };
    // };
    // const add_default = (key, object, status) => {
    //   json_ai[key] = {
    //     properties: object?._id ? object.properties || {} : { status },
    //   };
    // };

    // add_properties("demography_one", sini_object?.demography_one);
    // add_properties("people_spending", sini_object?.people_spending);
    // add_properties("landzone_one", sini_object?.landzone_one);
    // add_default(
    //   "flood_one",
    //   sini_object?.flood_one,
    //   "Tidak ada data kerawanan banjir"
    // );
    // add_default(
    //   "landslide_one",
    //   sini_object?.landslide_one,
    //   "Tidak ada data kerawanan longsor"
    // );
    // add_default(
    //   "tsunami_one",
    //   sini_object?.tsunami_one,
    //   "Tidak ada data kerawanan tsunami"
    // );
    // if (sini_object?.rdtr_one?._id) {
    //   json_ai.rdtr_one = {
    //     properties: { NAMZON_1: sini_object.rdtr_one.properties?.NAMZON_1 },
    //   };
    // }
    // const map_array = (key, array) => {
    //   json_ai[key] =
    //     array?.length > 0
    //       ? array.map((item) => ({ properties: item?.properties || {} }))
    //       : [];
    // };
    // map_array("landzone_neighbor", sini_object?.landzone_neighbor);
    // map_array("flood_neighbor", sini_object?.flood_neighbor);
    // map_array("landslide_neighbor", sini_object?.landslide_neighbor);
    // map_array("tsunami_neighbor", sini_object?.tsunami_neighbor);
    // add_properties("landvalue_one", sini_object?.landvalue_one);
    // map_array("landvalue_neighbor", sini_object?.landvalue_neighbor);
    // json_ai.poi_features = [];
    // json_ai.poi_resume_1 = sini_object?.poi_resume_1 || [];
    // json_ai.poi_resume_2 = sini_object?.poi_resume_2 || [];
    // json_ai.poi_resume_3 = sini_object?.poi_resume_3 || [];
    // if (sini_object?.poi_survey_1_features?.length > 0) {
    const {
      // jenis_bangunan_resume,
      average_sewa,
      min_sewa,
      max_sewa,
      count_sewa,
      // manifest_survey_1,
    } = sini_object;
    // let { poi_survey_1_features } = sini_object;

    //pake fields.name
    // const fields_used = [
    //   "DATA LINGKUNGAN",
    //   "Karakteristik Lokasi",
    //   "Karakteristik Kepadatan Pengembangan",
    //   "Karakteristik Pertumbuhan",
    //   "Issue Lingkungan",
    //   "Catatan Issue Lingkungan",
    //   "Analisis Risiko",
    //   "Catatan Analisis Risiko",
    //   "DATA PROPERTI",
    //   "Tipe Properti",
    //   "Aksesibilitas",
    //   "Data Jalan: Jumlah Jalur",
    //   "Data Jalan: Lebar Jalan",
    //   "Data Jalan: Jumlah Lajur",
    //   "Data Jalan: Material",
    //   "Data Jalan: Drainase",
    //   "Data Jalan: Penerangan",
    //   "Data Jalan: Kondisi Fisik",
    //   "Data Jalan: Intensitas Lalulintas",
    //   "Status Hunian",
    // ];
    // const fields = manifest_survey_1?.fields || [];
    // poi_survey_1_features = poi_survey_1_features.map((feature) => {
    //   let properties = {};
    //   fields_used.forEach((field_name) => {
    //     const field_key = fields.find(
    //       (field) => field.name === field_name
    //     )?.key;
    //     const value = feature?.properties?.[field_key];
    //     properties[field_name] = value;
    //   });
    //   return properties;
    // });
    json_ai = {
      ...json_ai,
      // poi_survey_1_features,
      // jenis_bangunan_resume,
      average_sewa,
      min_sewa,
      max_sewa,
      count_sewa,
    };
    // }

    body.json_ai = json_ai;


    delete body.sini_object;
    /*FINISH*/

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      }
    };

    // await timeout(10000)
    // console.log("body", body)

    const res = await axios.post(
      bun_server + `/sini_v2/appraisal/generate_ai_appraisal_summary`,
      body,
      config
    );

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_appraisal_summary_object",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "generate_ai_appraisal_summary",
    });

    dispatch(snackbar({
      is_open: true,
      status: "success",
      message: "Generate Summary Appraisal Berhasil"
    }))

  } catch (error) {

    let message = error.message
    if (error.code === 'ECONNABORTED') {
      message = "Timeout";
    } else if (error?.response?.data && typeof error?.response?.data === "string") {
      message = error?.response?.data
    } else if (error.message) {
      message = error.message
    }

    dispatch(snackbar({
      is_open: true,
      status: "info",
      message: "Tunggu Sebentar...",
    }))

    dispatch({
      type: "clear_loading_action",
      payload: "generate_ai_appraisal_summary",
    });

    if (message === "Timeout") {
      return message
    }
  }
};


/**
 * @route : get_ai_appraisal_summary
 * @endpoint : /sini_v2/appraisal/get_ai_appraisal_summary
 * @methode : get
 * @params : geo_layer_id, feature_key, field_key,
 * @body : -
 */
export const get_ai_appraisal_summary = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_appraisal_summary",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
      timeout: 25000
    };
    const { geo_layer_id, feature_key, field_key } = params;
    const res = await axios.get(
      bun_server +
      `/sini_v2/appraisal/get_ai_appraisal_summary?geo_layer_id=${geo_layer_id}&feature_key=${feature_key}&field_key=${field_key}`,
      config
    );

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_appraisal_summary_object",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_appraisal_summary",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_appraisal_summary",
    });
  }
};

/**
 * @route : get_data_pembanding
 * @endpoint : /sini_v2/appraisal/get_data_pembanding
 * @methode : post
 * @params : -
 * @body : latitude, longitude, tipeProperti, luasTanah, tahunDibangun, radius
 */
export const get_data_pembanding = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      bun_server + `/sini_v2/appraisal/get_data_pembanding`,
      body,
      config
    );


    if (res?.data?.length > 0) {
      dispatch(snackbar({
        is_open: true,
        status: "success",
        message: "Data pembanding berhasil didapatkan"
      }))
    } else {
      dispatch(snackbar({
        is_open: true,
        status: "warning",
        message: "Tidak ada data pembanding di sekitar"
      }))
    }


    dispatch({
      type: "clear_loading_action",
      payload: "get_data_pembanding",
    });
    return res
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_data_pembanding",
    });

    dispatch(snackbar({
      is_open: true,
      status: "error",
      message: error?.response?.data || "Gagal mendapatkan Data Pembanding"
    }))
    return;
  }
};
