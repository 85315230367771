/*eslint-disable*/
import React, { Component } from "react";
import dict from "../../Data/dict.json";

import { convert_conditional_statement_operator_to_readable } from "../../App/validation/formula_validation";

import ConditionalStatementEdit from "../editor_table/ConditionalStatementEdit";
import Modal from "../common_modal/Modal";

export default class ConditionalStatementInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_edit: false,
      conditional_statement_index_that_will_be_edited: -1,
    };
  }

  toggleModalEdit = (value) => {
    const { modal_edit } = this.state;
    this.setState({
      modal_edit: value || !modal_edit,
    });
  };

  handleConditionalStatementIndex = (index) => {
    this.setState({
      conditional_statement_index_that_will_be_edited: index,
    });
  };

  pushConditionalStatementList = (_function, _if, _then) => {
    const { conditional_statement_list } = this.props;

    conditional_statement_list.push({
      function: _function,
      if: _if,
      then: _then,
    });

    this.props.handleConditionalStatementList(conditional_statement_list);
  };

  handleDeleteConditionalStatement = () => {
    const new_conditional_statement_list =
      this.props.conditional_statement_list?.slice(0, -1) || [];
    this.props.handleConditionalStatementList(new_conditional_statement_list);
  };

  isConditionalStatementListStillCanAddLogic = () => {
    const { conditional_statement_list } = this.props;
    if (
      conditional_statement_list?.[conditional_statement_list.length - 1]
        ?.function === "if" ||
      conditional_statement_list?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { language, headers, conditional_statement_list, is_sidebar } =
      this.props;
    const { modal_edit, conditional_statement_index_that_will_be_edited } =
      this.state;

    const modal_edit_content = modal_edit && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_edit}
        onClose={this.toggleModalEdit}
      >
        <ConditionalStatementEdit
          headers={headers}
          language={language}
          conditional_statement_index_that_will_be_edited={
            conditional_statement_index_that_will_be_edited
          }
          handleConditionalStatementList={
            this.props.handleConditionalStatementList
          }
          conditional_statement_list={conditional_statement_list}
        />
      </Modal>
    );

    return (
      <div>
        <section>
          <label className="font_18 w_full">
            {dict["Conditional Statement"][language]}
          </label>
          <div className="paddingRight_30">
            {conditional_statement_list.map((condition, index) => {
              if (condition?.function === "if") {
                return (
                  <div
                    key={index}
                    className={`${
                      is_sidebar
                        ? "four-container"
                        : "four_container_10_40_10_40"
                    } paddingTop_10`}
                  >
                    <div className="center_perfect">
                      <label
                        className={`text_center ${
                          is_sidebar ? "font_12" : "font_16"
                        } `}
                      >
                        {index === 0
                          ? dict["IF"][language]
                          : dict["ELSE IF"][language]}
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        this.toggleModalEdit(true);
                        this.handleConditionalStatementIndex(index);
                      }}
                      className={`w_full h_30 border bg_white rounded_5 center_perfect pointer`}
                      data-mapid="clickIf"
                    >
                      <p className={"truncate"}>
                        {condition?.if
                          ?.map((object) =>
                            convert_conditional_statement_operator_to_readable(
                              object.content,
                              language,
                              dict
                            )
                          )
                          .join("")}
                      </p>
                    </div>
                    <div className="center_perfect">
                      <label
                        className={`text_center ${
                          is_sidebar ? "font_12" : "font_16"
                        } `}
                      >
                        {dict["THEN"][language]}
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        this.toggleModalEdit(true);
                        this.handleConditionalStatementIndex(index);
                      }}
                      className="w_full h_35 border bg_white rounded_5 center_perfect pointer"
                    >
                      <p className={`${is_sidebar && "truncate"} `}>
                        {condition?.then}{" "}
                      </p>
                    </div>
                    {/* <div className="center_perfect">
                    <div className="flex gap_5">
                      <div
                        className="button background_blue"
                        onClick={() => {
                          this.toggleModalEdit(true);
                          this.handleConditionalStatementIndex(index);
                        }}
                      >
                        {dict["Edit"][language]}
                      </div>
                    </div>
                  </div> */}
                  </div>
                );
              } else if (condition?.function === "else") {
                return (
                  <div
                    key={index}
                    className="two_container_100px_auto paddingTop_10"
                  >
                    <div className="center_perfect">
                      <label className="text_center font_16">
                        {dict["ELSE"][language]}
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        this.toggleModalEdit(true, index);
                        this.handleConditionalStatementIndex(index);
                      }}
                      className="w_full h_35 border bg_white rounded_5 center_perfect"
                      data-mapid="clickELSEsubject"
                    >
                      {condition?.then}
                    </div>
                  </div>
                );
              }
            })}

            <div
              className={`flex marginTop_20 justify_end`}
              style={{
                justifyContent:
                  this.isConditionalStatementListStillCanAddLogic()
                    ? "space-between"
                    : "flex-end",
              }}
            >
              {this.isConditionalStatementListStillCanAddLogic() && (
                <div>
                  <div className="w_full">
                    <label className="margin_right font_18 w_100 margin_bottom">
                      {dict["Add"][language]}:
                    </label>
                    <button
                      onClick={() => {
                        this.pushConditionalStatementList("if", [], "");
                      }}
                      className="button outline_black button_white margin_right margin_bottom"
                      data-mapid="clickBersyarat"
                    >
                      {dict["IF"][language]}
                    </button>
                    {conditional_statement_list?.length > 0 && (
                      <div
                        onClick={() => {
                          this.pushConditionalStatementList(
                            "else",
                            undefined,
                            ""
                          );
                        }}
                        className="button w_100 outline_black button_white"
                        data-mapid="clickELSE"
                      >
                        {dict["ELSE"][language]}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {conditional_statement_list?.length > 0 && (
                <div className="center_perfect">
                  <div
                    onClick={() => {
                      this.handleDeleteConditionalStatement();
                    }}
                    className="button background_red"
                  >
                    {dict["Delete"][language]}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section>{modal_edit_content}</section>
      </div>
    );
  }
}
