/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import ErrorFree from "../user/ErrorFree";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  searchLayersOnly,
  searchLayersOnlyPublic,
  downloadLayer,
  clearSearchLayer,
  loadMoreSearchLayersOnlyPublic,
  loadMoreSearchLayersOnly,
} from "../../App/actions/layerActions";
import { attachLayer } from "../../App/actions/layerNewActions";
import {
  get_data_premium_count,
  search_data_premium,
  get_data_premium_by_category_id,
} from "../../App/actions/moneyActions";
import { clone_layer } from "../../App/actions/propertiesActions";

/*PICTURE*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const sort_item = (array, lang, isFromSmall) => {
  if (lang) {
    if (isFromSmall) {
      function compare(a, b) {
        if (a?._id?.[lang] < b?._id?.[lang]) return -1;
        if (a?._id?.[lang] > b?._id?.[lang]) return 1;
        return 0;
      }
      return array.sort(compare);
    } else {
      function compare(a, b) {
        if (a?._id?.[lang] < b?._id?.[lang]) return 1;
        if (a?._id?.[lang] > b?._id?.[lang]) return -1;
        return 0;
      }
      return array.sort(compare);
    }
  } else {
    if (isFromSmall) {
      function compare(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }
      return array.sort(compare);
    } else {
      function compare(a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      }
      return array.sort(compare);
    }
  }
};

class SEARCH_LAYER extends Component {
  state = {
    search_params: "",
    category_id: "",
    geo_layer: {},
    mode: "premium", //private | public | premium
    layers_attach_id: [],
    layers_import_id: [],
    modal_block: false,
    data_skip: {
      private: 0,
      public: 0,
    },
    // geo_layer_list: [],
    isTyping: false,
    modal_error_free: false,
  };

  componentDidMount() {
    this.timer = null;
    const list_id = this.props.layer.geo_layer_list.map((l) => l._id);
    this.setState({ layers_attach_id: list_id });
    this.props.get_data_premium_count();
  }

  componentWillUnmount() {
    this.setState({
      search_params: "",
      geo_layer: {},
      mode: "private",
      data_skip: {
        private: 0,
        public: 0,
      },
    });
  }

  toggle_error_free = () => {
    this.setState({ modal_error_free: !this.state.modal_error_free });
  };

  on_get_data_premium_by_category_id = (category_id) => {
    this.setState({ category_id });
    this.props.get_data_premium_by_category_id(category_id);
  };

  toggleBlock = () => {
    this.setState({ modal_block: !this.state.modal_block });
  };

  handleMode = (mode) => {
    this.setState({
      mode,
      search_params: "",
      // geo_layer_list: []
    });
    this.props.clearSearchLayer();
  };

  handlePushProject = (geo_layer) => {
    const { mode } = this.state;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;

    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license ||
      mode !== "premium"
    ) {
      //jika lolos maka jalankan fungsi import data
      let { layers_attach_id } = this.state;
      layers_attach_id.push(geo_layer._id);
      this.setState({ geo_layer, layers_attach_id }, () => {
        const { geo_project } = this.props.project;
        const geo_project_id = geo_project._id;
        const body = {
          geo_project_id,
          geo_layer,
        };
        this.props.attachLayer(body);
      });
    } else {
      //jika tidak lolos maka toggle penawaran pricing
      this.toggle_error_free();
    }
  };

  handleDownload = (geo_layer) => {
    const { mode } = this.state;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;

    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license ||
      mode !== "premium"
    ) {
      //jika lolos maka jalankan fungsi import data
      let { layers_import_id } = this.state;
      layers_import_id.push(geo_layer._id);
      const geo_project_link = this?.props?.project?.geo_project?.link;
      this.setState({ geo_layer, layers_import_id }, () => {
        const { geo_project } = this.props.project;
        const { user } = this.props.auth;
        const geo_layer_id = geo_layer._id;
        const body = {
          geo_layer_id: geo_layer_id,
          geo_project_id: geo_project._id,
          user_id: user._id,
          new_name: geo_layer?.name,
        };

        this.props.clone_layer(body, geo_project_link);
        // this.props.downloadLayer(body);
      });
    } else {
      //jika tidak lolos maka toggle penawaran pricing
      this.toggle_error_free();
    }
  };

  handleChange(e) {
    if (this.props.layer.search_result_list.geo_layer_list.length > 0) {
      this.props.clearSearchLayer();
    }
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
      isTyping: true,
      data_skip: {
        private: 0,
        public: 0,
      },
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_params, mode } = this.state;
    this.setState({ category_id: "" });
    if (search_params) {
      if (mode === "private") {
        this.props.searchLayersOnly(search_params);
        this.setState({
          data_skip: {
            ...this.state.data_skip,
            private: this.state.data_skip.private + 20,
          },
          isTyping: false,
        });
      } else if (mode === "public") {
        this.props.searchLayersOnlyPublic(search_params);
        this.setState({
          data_skip: {
            ...this.state.data_skip,
            public: this.state.data_skip.public + 20,
          },
          isTyping: false,
        });
      } else if (mode === "premium") {
        this.props.search_data_premium(search_params);
        this.setState({ isTyping: false });
      }
    }
  }

  handleLoadMore = () => {
    const { search_params, mode, data_skip } = this.state;
    if (search_params) {
      if (mode === "private" && data_skip.private >= 20) {
        this.props.loadMoreSearchLayersOnly(search_params, data_skip.private);
        this.setState({
          data_skip: {
            ...this.state.data_skip,
            private: this.state.data_skip.private + 20,
          },
        });
      } else if (mode === "public" && data_skip.public >= 20) {
        this.props.loadMoreSearchLayersOnlyPublic(
          search_params,
          data_skip.public
        );
        this.setState({
          data_skip: {
            ...this.state.data_skip,
            public: this.state.data_skip.public + 20,
          },
        });
      }
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      search_params,
      mode,
      layers_attach_id,
      layers_import_id,
      category_id,
      modal_error_free,
    } = this.state;
    const geo_layer_state = this.state.geo_layer;

    //global props
    const { loading_status, loading_item } = this.props.layer;
    const { geo_layer_list } = this.props.layer.search_result_list;
    let { data_premium_count } = this.props.blog;

    data_premium_count = sort_item(data_premium_count, language, true);

    //content
    let result_content;
    let loading_content = null;

    if (loading_status && loading_item === "search_layer") {
      result_content = (
        <div className="container_grey">{dict["Searching"][language]}...</div>
      );
    } else if (geo_layer_list.length === 0) {
      result_content = !this.state.isTyping && !loading_status && (
        <div className="container_grey">{dict["No result"][language]}</div>
      );
    } else {
      result_content = (
        <div>
          {geo_layer_list.map((geo_layer, idx) => {
            const { _id, name, geo_project } = geo_layer;
            const description = geo_layer?.description;
            const geo_project_name = geo_project?.name;
            const full_name = geo_layer?.user?.full_name
              ? geo_layer?.user?.full_name
              : geo_layer?.user?.name;
            const profile_picture = geo_layer?.user?.profile_picture
              ? geo_layer?.user?.profile_picture
              : {};
            let button_attach = null;
            let button_download = null;
            if (
              loading_status &&
              loading_item === "push_project_attach" &&
              geo_layer_state._id === _id
            ) {
              button_attach = (
                <div className="button background_blue">
                  {dict["Importing"][language]}...
                </div>
              );
            } else if (
              loading_status &&
              loading_item === "download_layer" &&
              geo_layer_state._id === _id
            ) {
              button_download = (
                <button className="button background_blue">
                  {dict["Downloading"][language]}...
                </button>
              );
            } else {
              button_attach = (
                <main style={{ marginBottom: "10px" }}>
                  <button
                    onClick={this.handlePushProject.bind(this, geo_layer)}
                    className="button background_blue"
                    data-mapid={`clickAttach-${idx}`}
                  >
                    {dict["Attach"][language]}
                  </button>
                  <section className="text_inferior">
                    {dict["Attach layers"][language]}{" "}
                    <b>{dict["NOT EDITABLE"][language]}</b>
                  </section>
                </main>
              );
              button_download = (
                <main>
                  <button
                    onClick={this.handleDownload.bind(this, geo_layer)}
                    className="button background_blue"
                    data-mapid={`clickImport-${idx}`}
                  >
                    {dict["Import"][language]}
                  </button>
                  <section className="text_inferior">
                    {dict["Import layers"][language]}{" "}
                    <b>{dict["EDITABLE"][language]}</b>
                  </section>
                </main>
              );
              if (layers_attach_id.includes(_id)) {
                button_attach = (
                  <main style={{ marginBottom: "10px" }}>
                    <button className="button background_blue" id="grey">
                      {dict["Attached"][language]}
                    </button>
                    <section className="text_inferior">
                      {dict["Attach layers"][language]}{" "}
                      <b>{dict["NOT EDITABLE"][language]}</b>
                    </section>
                  </main>
                );
              }
              if (layers_import_id.includes(_id)) {
                button_download = (
                  <main>
                    <button className="button background_blue" id="grey">
                      {dict["Imported"][language]}
                    </button>
                    <section className="text_inferior">
                      {dict["Import layers"][language]}{" "}
                      <b>{dict["EDITABLE"][language]}</b>
                    </section>
                  </main>
                );
              }
            }
            return (
              <main
                key={idx}
                className="container_grey"
                style={{ marginBottom: "10px" }}
              >
                <main className="two_container_auto">
                  <section style={{ textAlign: "left" }}>
                    <div className="text_bold">{name}</div>
                    {description && (
                      <div className="text_inferior">{description}</div>
                    )}
                    <div className="text_inferior">
                      {dict["Project"][language]}: {geo_project_name}
                    </div>
                    <div
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <p
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name}
                    </p>
                    <style>
                      {`.photo_${_id}{
                      background: url(${
                        profile_picture && profile_picture.url_compressed
                          ? profile_picture.url_compressed
                          : profile_picture &&
                            profile_picture.url &&
                            profile_picture.url !==
                              "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                            profile_picture.url !== "-" &&
                            profile_picture.url !== "default"
                          ? profile_picture.url
                          : pic_static
                      }) no-repeat center center/ cover;
                      background-color: #c1c1c1;
                      height:40px;
                      width:40px;
                      border-radius: 50%;
                      border: 2px solid #2196f3;
                      display: inline-block;
                      align: center;
                      vertical-align: middle;
        }`}
                    </style>
                  </section>
                  <section style={{ textAlign: "right" }}>
                    {button_attach}
                    {button_download}
                  </section>
                </main>
              </main>
            );
          })}
        </div>
      );
    }

    const loadMoreButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={this.handleLoadMore}
      >
        <button className="button background_blue">Load More</button>
      </div>
    );

    const modal_error_free_content = modal_error_free && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_error_free}
        onClose={this.toggle_error_free}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    return (
      <main style={{ height: "80vh", overflow: "auto" }}>
        {modal_error_free_content}
        <main>
          <section className="text_header">
            {dict["Search Layer"][language]}{" "}
            {search_params &&
              !(loading_status && loading_item === "search_layer") &&
              `(${dict["Result"][language]}: ${geo_layer_list.length} data)`}
          </section>

          <section className="text_inferior" style={{ marginBottom: "10px" }}>
            {
              dict[
                "Search layer from all your project or public layer then import it to this project."
              ][language]
            }
          </section>

          <section style={{ textAlign: "center" }}>
            <button
              onClick={this.handleMode.bind(this, "private")}
              className="button noselect use_margin"
              id={mode === "private" ? "blue" : "almost_white"}
              data-mapid="clickDataKamu"
            >
              {dict["Your Layers"][language]}
            </button>
            <button
              onClick={this.handleMode.bind(this, "public")}
              className="button noselect use_margin"
              id={mode === "public" ? "blue" : "almost_white"}
              data-mapid="clickPublikData"
            >
              {dict["Public Layers"][language]}
            </button>
            <button
              onClick={this.handleMode.bind(this, "premium")}
              className="button noselect use_margin"
              id={mode === "premium" ? "blue" : "almost_white"}
              data-mapid="clickDataPremium"
            >
              Data Premium
            </button>
          </section>

          <input
            className="input_square margin_bottom"
            type="text"
            placeholder={dict["Search layers"][language]}
            autoComplete="off"
            name="search_params"
            value={search_params}
            onChange={this.handleChange.bind(this)}
            onKeyDown={this.handleKeyDown.bind(this)}
            data-mapid="searchDataKamu"
          />

          {mode === "premium" && (
            <section>
              <h1 className="text_bold margin_bottom">Etalase data</h1>
              {data_premium_count.map((item, idx) => {
                let name;
                if (item?._id?.idn) {
                  if (language === "ina") {
                    name = item?._id?.idn;
                  } else {
                    name = item?._id?.eng;
                  }
                } else {
                  name = "Other";
                }
                return (
                  <section
                    key={idx}
                    className={`margin_right margin_bottom container_flat container_small ${
                      category_id === item?._id?._id
                        ? "background_blue"
                        : "background_grey"
                    }`}
                    onClick={this.on_get_data_premium_by_category_id.bind(
                      this,
                      item?._id?._id
                    )}
                    data-mapid={`clickPremium-${idx}`}
                  >
                    <p className="text_bold">{name}</p>
                    <p className="text_medium">{item?.sum}</p>
                  </section>
                );
              })}
            </section>
          )}

          <section
            style={{ height: "calc(( 80vh - 130px ))", overflowY: "auto" }}
          >
            {(search_params || category_id) && result_content}
            {loading_content}
            {geo_layer_list.length % 20 === 0 &&
              geo_layer_list.length !== 0 &&
              loadMoreButton}
          </section>
        </main>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  payment: state.payment,
  blog: state.blog,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  clone_layer,
  searchLayersOnly,
  searchLayersOnlyPublic,
  downloadLayer,
  clearSearchLayer,
  attachLayer,
  loadMoreSearchLayersOnlyPublic,
  loadMoreSearchLayersOnly,
  get_data_premium_count,
  search_data_premium,
  get_data_premium_by_category_id,
})(SEARCH_LAYER);
