/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import colors_layer_poi from "../../Data/colors_layer_poi.json";

/*CONST*/

class LAYER_S_POI extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }

      // this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_circle_radius,
      is_sini_visible,
    } = this.props.sini;
    const visibility =
      sini_map_show.includes("poi") && is_sini_visible ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    merge_array.forEach((item) => {
      const { request_id } = item;
      const features = item?.poi_features || [];
      const geojson = {
        type: "FeatureCollection",
        features: features,
      };
      const layer_id = `poi_${request_id}`;
      if (!map_object.getSource(layer_id)) {
        map_object.addSource(layer_id, {
          type: "geojson",
          data: geojson,
        });
      } else {
        map_object.getSource(layer_id).setData(geojson);
      }

      if (!map_object.getLayer(layer_id)) {
        map_object.addLayer({
          id: layer_id,
          source: layer_id,
          type: "circle",
          paint: {
            "circle-color": colors_layer_poi,
            "circle-radius": Number(sini_circle_radius),
            "circle-stroke-width": 1,
            "circle-stroke-color": "#000",
          },
          layout: {
            visibility: visibility,
          },
        });
      } else {
        map_object.setPaintProperty(
          layer_id,
          "circle-radius",
          Number(sini_circle_radius)
        );
        map_object.setLayoutProperty(layer_id, "visibility", visibility);
      }
    });
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_POI);
