/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import M_S_DEMOGRAPHY_ONE from "../libre_marker_sini/M_S_DEMOGRAPHY_ONE";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const color_line = "#104585";
const color_fill = "#104585";

class LAYER_S_DEMOGRAPHY_ONE extends Component {
  componentDidUpdate(prevProps) {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }
      // this.on_render_layer();
    }

    if (
      this.props.sini.fly_init_update &&
      !this.props.sini.is_stop_zoom &&
      (prevProps.sini.fly_init_update !== this.props.sini.fly_init_update ||
        prevProps.sini.request_id_active !== this.props.sini.request_id_active)
    ) {
      this.on_fly_to();
    }
  }

  on_render_layer = () => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      is_sini_visible,
    } = this.props.sini;
    const visibility =
      sini_map_show.includes("demography_one") && is_sini_visible
        ? "visible"
        : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const demography_one = item?.demography_one;
        const layer_id_fill = `geojson_demography_one_${request_id}`;
        const layer_id_line = `geojson_demography_one_line_${request_id}`;

        if (demography_one?._id) {
          const geojson_demography_one = {
            type: "FeatureCollection",
            features: [demography_one],
          };

          // Add or update source
          if (!map_object.getSource(layer_id_fill)) {
            map_object.addSource(layer_id_fill, {
              type: "geojson",
              data: geojson_demography_one,
            });
          } else {
            map_object.getSource(layer_id_fill).setData(geojson_demography_one);
          }

          // Add or update fill layer
          if (!map_object.getLayer(layer_id_fill)) {
            map_object.addLayer({
              id: layer_id_fill,
              source: layer_id_fill,
              type: "fill",
              paint: {
                "fill-color": color_fill,
                "fill-opacity": 0.5,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_fill,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_fill,
              "fill-opacity",
              Number(sini_polygon_fill_opacity)
            );
          }

          // Add or update line layer
          if (!map_object.getLayer(layer_id_line)) {
            map_object.addLayer({
              id: layer_id_line,
              source: layer_id_fill,
              type: "line",
              paint: {
                "line-color": color_line,
                "line-width": 1,
                "line-opacity": 1,
                "line-gap-width": 0,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_line,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_line,
              "line-width",
              Number(sini_polygon_line_width)
            );
          }
        }
      });
    }
  };

  on_fly_to = () => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const { sini_v2_list, ai_parent_detail_list, request_id_active } =
      this.props.sini;

    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (merge_array.length > 0) {
      const sini_object = merge_array.find(
        (item) => item.request_id === request_id_active
      );

      if (sini_object) {
        const { demography_one, long, lat } = sini_object;

        if (
          demography_one?._id &&
          demography_one?.geometry &&
          demography_one?.properties
        ) {
          if (map_object) {
            map_object.flyTo({
              center: [long, lat],
              zoom: 13,
            });
          }
        }
      }
    }
  };

  render() {
    return (
      <>
        <M_S_DEMOGRAPHY_ONE />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps)(LAYER_S_DEMOGRAPHY_ONE);
