/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { MenuItem, Select } from "@mui/material";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  get_api_keys,
  create_api_key,
  delete_api_key,
  get_basemap_styles,
  get_monthly_report,
} from "../../App/actions/map_service_actions";

/*PICTURE*/

/*FUNCTION*/
import capitalize_first_letter from "../../App/validation/capitalize_first_letter";

/*DATA*/
import dict from "../../Data/dict.json";
import months from "../../Data/months.json";

/*CONST*/
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);
const options_bar = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      anchor: "end",
      offset: -20,
      align: "start",
    },
  },
  animation: {
    duration: 500,
  },
};

const types = [
  "basemap",
  "routing",
  "geocode",
  "routing_n_toll",
  "isochrone",
  "elevation",
];
const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

class MAP_SERVICE_MENU_PAGE extends Component {
  state = {
    new_api_key_name: "",
    new_api_key_description: "",
    tab: 0, // State to manage the active tab
    modal_delete_api_key: false, // State to control the delete modal
    api_key_to_delete: null, // State to store the API key to be deleted
    copied_key: null, // State to store the copied API key for feedback
    tab_style: 0,
    analytic_date: "",
    type: "",
    api_key_id: "",
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    this.props.get_api_keys();
    const month_code = months[new Date().getMonth()].code;
    const { api_keys } = this.props.map_service_reducer;

    this.setState({
      analytic_date: `${new Date().getFullYear()}-${month_code}-01`,
      api_key_id: api_keys?.[0]?._id,
      type: "basemap",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.tab !== this.state.tab && this.state.tab === 1) ||
      prevState.analytic_date !== this.state.analytic_date ||
      prevState.api_key_id !== this.state.api_key_id ||
      prevState.type !== this.state.type ||
      prevState.year !== this.state.year
    ) {
      const { api_keys } = this.props.map_service_reducer;
      const { api_key_id, analytic_date, type, year } = this.state;
      if (api_keys?.length > 0) {
        const body = {
          api_key: api_key_id || api_keys?.[0]?._id,
          month: `${year}-${analytic_date.split("-")[1]}-01`,
          type: type,
        };
        this.props.get_monthly_report(body);
      }
    }
  }

  handle_select_api_key = (id) => {
    this.setState({
      api_key_id: id,
    });
  };

  handle_type = (type) => {
    this.setState({
      type,
    });
  };

  handle_analytic_date = (month_code, year) => {
    this.setState({
      analytic_date: `${year}-${month_code}-01`,
    });
  };

  on_set_new_api_key_name = (e) => {
    this.setState({ new_api_key_name: e.target.value });
  };

  on_set_new_api_key_description = (e) => {
    this.setState({ new_api_key_description: e.target.value });
  };

  on_create_api_key = () => {
    const { new_api_key_name, new_api_key_description } = this.state;
    if (new_api_key_name) {
      const body = {
        name: new_api_key_name,
        description: new_api_key_description,
      };
      this.props.create_api_key(body);
      this.setState({ new_api_key_name: "", new_api_key_description: "" });
    }
  };

  toggle_delete_modal = (apiKey = null) => {
    this.setState({
      modal_delete_api_key: !this.state.modal_delete_api_key,
      api_key_to_delete: apiKey,
    });
  };

  confirm_delete_api_key = () => {
    const { api_key_to_delete } = this.state;
    if (api_key_to_delete) {
      this.props.delete_api_key(api_key_to_delete);
      this.toggle_delete_modal(); // Close the modal after deletion
    }
  };

  // Function to copy API key to clipboard
  copy_to_clipboard = (apiKey) => {
    navigator.clipboard.writeText(apiKey).then(() => {
      this.setState({ copied_key: apiKey });
      setTimeout(() => {
        this.setState({ copied_key: null }); // Reset after 3 seconds
      }, 3000);
    });
  };

  render() {
    // Local storage
    const language = localStorage?.language || "ina";

    // Global props
    const {
      api_keys,
      loading_status,
      loading_item,
      basemap_styles,
      monthly_report,
    } = this.props.map_service_reducer;

    let date_label = [];
    let values = [];
    let total = 0;
    monthly_report?.access_count?.forEach(({ _id, count }) => {
      date_label.push(_id);
      values.push(count);
      total += count;
    });

    // state
    const { analytic_date, api_key_id, type, year } = this.state;

    // Content for tabs
    const tabs = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          margin: "1rem 0",
        }}
      >
        <button
          onClick={() => this.setState({ tab: 0 })}
          className={`button_small ${
            this.state.tab === 0 ? "background_blue" : "background_grey"
          }`}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={18}
            height={18}
          >
            <path
              fillRule="evenodd"
              d="M15.75 1.5a6.75 6.75 0 0 0-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 0 0-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 0 0 .75-.75v-1.5h1.5A.75.75 0 0 0 9 19.5V18h1.5a.75.75 0 0 0 .53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1 0 15.75 1.5Zm0 3a.75.75 0 0 0 0 1.5A2.25 2.25 0 0 1 18 8.25a.75.75 0 0 0 1.5 0 3.75 3.75 0 0 0-3.75-3.75Z"
              clipRule="evenodd"
            />
          </svg>
          API Keys
        </button>
        <button
          onClick={() => this.setState({ tab: 1 })}
          className={`button_small ${
            this.state.tab === 1 ? "background_blue" : "background_grey"
          }`}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={18}
            height={18}
          >
            <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
          </svg>
          Analytics
        </button>
        <button
          onClick={() => {
            this.props.get_basemap_styles();
            this.setState({ tab: 2 });
          }}
          className={`button_small ${
            this.state.tab === 2 ? "background_blue" : "background_grey"
          }`}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={18}
            height={18}
          >
            <path d="M20.5 3.5l-5.47 2.18L8.47 2.18a.996.996 0 0 0-.94 0L3.5 3.5c-.3.12-.5.41-.5.74v15.54c0 .33.2.62.5.74l5.47-2.18 6.56 3.5c.3.16.68.1.94 0l4.56-1.81c.3-.12.5-.41.5-.74V4.24c0-.33-.2-.62-.5-.74zM14 19.06l-4-2.13V4.94l4 2.13v12zM6 17.82V5.87l2-.87v11.94l-2 .88zm12 0l-2 .88V6.76l2-.87v11.93z" />
          </svg>
          Styles
        </button>
      </div>
    );

    // API Keys content
    const api_keys_content = (
      <div>
        <h2>{dict?.["API Keys"]?.[language]}</h2>
        <p>
          {
            dict?.[
              "Manage your API keys here. You can create, delete, and review them."
            ]?.[language]
          }
        </p>

        {/* Input form for creating a new API key */}
        <div className="margin_bottom">
          <input
            type="text"
            placeholder={dict?.["API Key Name"]?.[language]}
            value={this.state.new_api_key_name}
            onChange={this.on_set_new_api_key_name}
            style={{ marginBottom: "10px", padding: "8px", width: "100%" }}
          />
          <input
            type="text"
            placeholder={dict?.["API Key Description"]?.[language]}
            value={this.state.new_api_key_description}
            onChange={this.on_set_new_api_key_description}
            style={{ marginBottom: "10px", padding: "8px", width: "100%" }}
          />
          <button
            className="button_small background_blue"
            onClick={this.on_create_api_key}
            style={{ padding: "8px 16px" }}
          >
            {dict?.["Create New Key"]?.[language]}
          </button>
        </div>

        {/* Display list of API keys */}
        {loading_status && loading_item === "get_api_keys" ? (
          <p>{dict?.["Loading API keys..."]?.[language]}</p>
        ) : (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {api_keys && api_keys.length > 0 ? (
              api_keys.map((apiKey) => (
                <li
                  key={apiKey._id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "16px",
                    marginBottom: "15px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      marginBottom: "8px",
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span>
                      <strong>{dict?.["API Key"]?.[language]}:</strong>{" "}
                      {apiKey._id}
                    </span>
                    <button
                      className="button_small background_blue"
                      onClick={() => this.copy_to_clipboard(apiKey._id)}
                      style={{
                        padding: "4px 10px",
                        marginLeft: "10px",
                      }}
                    >
                      {this.state.copied_key === apiKey._id
                        ? dict?.["Copied!"]?.[language]
                        : dict?.["Copy"]?.[language]}
                    </button>
                  </div>
                  <div>
                    <strong>{dict?.["API Key Name"]?.[language]}:</strong>{" "}
                    {apiKey.name}
                  </div>
                  <div>
                    <strong>{dict?.["Description"]?.[language]}:</strong>{" "}
                    {apiKey.description || dict?.["No description"]?.[language]}
                  </div>
                  <div>
                    <strong>{dict?.["Created At"]?.[language]}:</strong>{" "}
                    {/* date local date month year */}
                    {new Date(apiKey.created_at).toLocaleDateString()}
                  </div>
                  <div>
                    <strong>{dict?.["Total Requests"]?.[language]}:</strong>{" "}
                    {apiKey.sum}
                  </div>
                  <button
                    className="button_small background_red"
                    onClick={() => this.toggle_delete_modal(apiKey._id)}
                    style={{ marginTop: "10px", padding: "6px 12px" }}
                  >
                    {dict?.["Delete"]?.[language]}
                  </button>
                </li>
              ))
            ) : (
              <p>{dict?.["No API keys available"]?.[language]}</p>
            )}
          </ul>
        )}
      </div>
    );

    // Basemap styles content
    const examples_content = (
      <div>
        <h2>{dict?.["Examples"]?.[language]}</h2>
        <p className="margin_bottom">
          {
            dict?.["View available basemap styles and their related URLs."]?.[
              language
            ]
          }
        </p>
        {loading_status && loading_item === "get_basemap_styles" ? (
          <p>{dict?.["Loading Basemap Styles..."]?.[language]}</p>
        ) : (
          <>
            <div className="" style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={() => this.setState({ tab_style: 0 })}
                className={`button_small margin_bottom ${
                  this.state.tab_style === 0
                    ? "background_blue"
                    : "background_grey"
                }`}
              >
                <h3>{dict?.["Public Styles"]?.[language]}</h3>
              </button>
              <button
                onClick={() => this.setState({ tab_style: 1 })}
                className={`button_small margin_bottom ${
                  this.state.tab_style === 1
                    ? "background_blue"
                    : "background_grey"
                }`}
              >
                <h3>{dict?.["Private Styles"]?.[language]}</h3>
              </button>
            </div>
            {this.state.tab_style === 0 && (
              <>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {basemap_styles.public_style &&
                  basemap_styles.public_style.length > 0 ? (
                    basemap_styles.public_style.map((style, index) => (
                      <li key={index} className="basemap_styles">
                        <div>
                          <div
                            style={{
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                              marginBottom: "8px",
                              color: "#333",
                            }}
                          >
                            {style.name}
                          </div>
                          <div className="margin_bottom">
                            {style.description ||
                              dict?.["No description"]?.[language]}
                          </div>
                          <div>
                            <strong style={{ fontWeight: "bold" }}>
                              {dict?.["Identifier"]?.[language]}:
                            </strong>{" "}
                            {style.identifier}
                          </div>
                          <div>
                            <strong style={{ fontWeight: "bold" }}>
                              {dict?.["GL Style"]?.[language]}
                            </strong>{" "}
                            <small>
                              {this.state.copied_key ===
                              `${style["GL Style"]}${
                                api_keys.length > 0 && api_keys[0]?._id
                              }`
                                ? "copied"
                                : ""}
                            </small>
                            <div className="basemap_copy">
                              <button
                                className="basemap_copy_box"
                                onClick={() =>
                                  this.copy_to_clipboard(
                                    `${style["GL Style"]}${
                                      api_keys.length > 0 && api_keys[0]?._id
                                    }`
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                                </svg>
                                {style["GL Style"]}
                                {api_keys.length > 0 && api_keys[0]?._id}
                              </button>
                            </div>
                          </div>
                          <div>
                            <strong style={{ fontWeight: "bold" }}>
                              {dict?.["TileJSON"]?.[language]}
                            </strong>{" "}
                            <small>
                              {this.state.copied_key ===
                              `${style.TileJSON}${
                                api_keys.length > 0 && api_keys[0]?._id
                              }`
                                ? "copied"
                                : ""}
                            </small>
                            <div className="basemap_copy">
                              <button
                                className="basemap_copy_box"
                                onClick={() =>
                                  this.copy_to_clipboard(
                                    `${style.TileJSON}${
                                      api_keys.length > 0 && api_keys[0]?._id
                                    }`
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                                </svg>
                                {style["TileJSON"]}
                                {api_keys.length > 0 && api_keys[0]?._id}
                              </button>
                            </div>
                          </div>
                          <div>
                            <strong style={{ fontWeight: "bold" }}>
                              {dict?.["WMTS"]?.[language]}
                            </strong>{" "}
                            <small>
                              {this.state.copied_key ===
                              `${style.WMTS}${
                                api_keys.length > 0 && api_keys[0]?._id
                              }`
                                ? "copied"
                                : ""}
                            </small>
                            <div className="basemap_copy">
                              <button
                                className="basemap_copy_box"
                                onClick={() =>
                                  this.copy_to_clipboard(
                                    `${style.WMTS}${
                                      api_keys.length > 0 && api_keys[0]?._id
                                    }`
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                                </svg>
                                {style["WMTS"]}
                                {api_keys.length > 0 && api_keys[0]?._id}
                              </button>
                            </div>
                          </div>
                          <div>
                            <strong style={{ fontWeight: "bold" }}>
                              {dict?.["XYZ"]?.[language]}
                            </strong>{" "}
                            <small>
                              {this.state.copied_key ===
                              `${style.XYZ}${
                                api_keys.length > 0 && api_keys[0]?._id
                              }`
                                ? "copied"
                                : ""}
                            </small>
                            <div className="basemap_copy">
                              <button
                                className="basemap_copy_box"
                                onClick={() =>
                                  this.copy_to_clipboard(
                                    `${style.XYZ}${
                                      api_keys.length > 0 && api_keys[0]?._id
                                    }`
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                                </svg>
                                {style["XYZ"]}
                                {api_keys.length > 0 && api_keys[0]?._id}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="margin_top margin_left margin_right">
                          <img
                            src={style.thumbnail}
                            alt={style.description}
                            style={{ width: "100%" }}
                            loading="lazy"
                          />
                          <a
                            href={`https://basemap.mapid.io/styles/${
                              style.identifier
                            }/?key=${
                              api_keys.length > 0 && api_keys[0]?._id
                            }&vector#5/-0.79/113.92`}
                            target="_blank"
                            rel="noreferrer"
                            className="button_small margin_right"
                            id="blue"
                          >
                            {dict?.["View"]?.[language]} Vector
                          </a>
                          <a
                            href={`https://basemap.mapid.io/styles/${
                              style.identifier
                            }/?key=${
                              api_keys.length > 0 && api_keys[0]?._id
                            }&raster#5/-0.79/113.92`}
                            target="_blank"
                            rel="noreferrer"
                            className="button_small background_blue"
                            id="blue"
                          >
                            {dict?.["View"]?.[language]} Raster
                          </a>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>{dict?.["There is no public style yet"]?.[language]}</p>
                  )}
                </ul>
              </>
            )}

            {this.state.tab_style === 1 && (
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {basemap_styles.private_style &&
                basemap_styles.private_style.length > 0 ? (
                  basemap_styles.private_style.map((style, index) => (
                    <li key={index} className="basemap_styles">
                      <div>
                        <div
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            marginBottom: "8px",
                            color: "#333",
                          }}
                        >
                          {style.name}
                        </div>
                        <div className="margin_bottom">
                          {style.description ||
                            dict?.["No description"]?.[language]}
                        </div>
                        <div>
                          <strong style={{ fontWeight: "bold" }}>
                            {dict?.["Identifier"]?.[language]}:
                          </strong>{" "}
                          {style.identifier}
                        </div>
                        <div>
                          <strong style={{ fontWeight: "bold" }}>
                            {dict?.["GL Style"]?.[language]}
                          </strong>{" "}
                          <small>
                            {this.state.copied_key ===
                            `${style["GL Style"]}${
                              api_keys.length > 0 && api_keys[0]?._id
                            }`
                              ? "copied"
                              : ""}
                          </small>
                          <div className="basemap_copy">
                            <button
                              className="basemap_copy_box"
                              onClick={() =>
                                this.copy_to_clipboard(
                                  `${style["GL Style"]}${
                                    api_keys.length > 0 && api_keys[0]?._id
                                  }`
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width={16}
                              >
                                <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                              </svg>
                              {style["GL Style"]}
                              {api_keys.length > 0 && api_keys[0]?._id}
                            </button>
                          </div>
                        </div>
                        <div>
                          <strong style={{ fontWeight: "bold" }}>
                            {dict?.["TileJSON"]?.[language]}
                          </strong>{" "}
                          <small>
                            {this.state.copied_key ===
                            `${style.TileJSON}${
                              api_keys.length > 0 && api_keys[0]?._id
                            }`
                              ? "copied"
                              : ""}
                          </small>
                          <div className="basemap_copy">
                            <button
                              className="basemap_copy_box"
                              onClick={() =>
                                this.copy_to_clipboard(
                                  `${style.TileJSON}${
                                    api_keys.length > 0 && api_keys[0]?._id
                                  }`
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width={16}
                              >
                                <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                              </svg>
                              {style["TileJSON"]}
                              {api_keys.length > 0 && api_keys[0]?._id}
                            </button>
                          </div>
                        </div>
                        <div>
                          <strong style={{ fontWeight: "bold" }}>
                            {dict?.["WMTS"]?.[language]}
                          </strong>{" "}
                          <small>
                            {this.state.copied_key ===
                            `${style.WMTS}${
                              api_keys.length > 0 && api_keys[0]?._id
                            }`
                              ? "copied"
                              : ""}
                          </small>
                          <div className="basemap_copy">
                            <button
                              className="basemap_copy_box"
                              onClick={() =>
                                this.copy_to_clipboard(
                                  `${style.WMTS}${
                                    api_keys.length > 0 && api_keys[0]?._id
                                  }`
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width={16}
                              >
                                <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                              </svg>
                              {style["WMTS"]}
                              {api_keys.length > 0 && api_keys[0]?._id}
                            </button>
                          </div>
                        </div>
                        <div>
                          <strong style={{ fontWeight: "bold" }}>
                            {dict?.["XYZ"]?.[language]}
                          </strong>{" "}
                          <small>
                            {this.state.copied_key ===
                            `${style.XYZ}${
                              api_keys.length > 0 && api_keys[0]?._id
                            }`
                              ? "copied"
                              : ""}
                          </small>
                          <div className="basemap_copy">
                            <button
                              className="basemap_copy_box"
                              onClick={() =>
                                this.copy_to_clipboard(
                                  `${style.XYZ}${
                                    api_keys.length > 0 && api_keys[0]?._id
                                  }`
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width={16}
                              >
                                <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                                <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                              </svg>
                              {style["XYZ"]}
                              {api_keys.length > 0 && api_keys[0]?._id}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="margin_top margin_left margin_right">
                        <img
                          src={style.thumbnail}
                          alt={style.description}
                          style={{ width: "100%" }}
                          loading="lazy"
                        />
                        <a
                          href={`https://basemap.mapid.io/styles/${
                            style.identifier
                          }/?key=${
                            api_keys.length > 0 && api_keys[0]?._id
                          }&vector#5/-0.79/113.92`}
                          target="_blank"
                          rel="noreferrer"
                          className="button_small margin_right"
                          id="blue"
                        >
                          {dict?.["View"]?.[language]} Vector
                        </a>
                        <a
                          href={`https://basemap.mapid.io/styles/${
                            style.identifier
                          }/?key=${
                            api_keys.length > 0 && api_keys[0]?._id
                          }&raster#5/-0.79/113.92`}
                          target="_blank"
                          rel="noreferrer"
                          className="button_small background_blue"
                          id="blue"
                        >
                          {dict?.["View"]?.[language]} Raster
                        </a>
                      </div>
                    </li>
                  ))
                ) : (
                  <p>{dict?.["You do not have private styles"]?.[language]}</p>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    );

    // Analytics content
    const analytics_content = (
      <div className="flex flex_col gap_20">
        {api_keys.length > 0 && (
          <>
            <header className="flex justify_between">
              <span>
                <h2>{dict?.["Analytics"]?.[language]}</h2>
                <p>
                  {
                    dict?.[
                      "View your basemap and routing usage in real-time."
                    ]?.[language]
                  }
                </p>
              </span>
            </header>
            <div className="center_right wrap gap_10">
              <div>
                <label className="text_center block w_full">Key</label>
                <Select
                  className="w_full bg_white h_40"
                  placeholder={dict["Select"][language]}
                  value={api_key_id || api_keys?.[0]?._id}
                  onChange={(e) => {
                    this.handle_select_api_key(e?.target?.value);
                  }}
                >
                  {api_keys.map(({ name, _id }) => {
                    return (
                      <MenuItem key={name} value={_id}>
                        {name === "" ? "-" : name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div>
                <label className="text_center block w_full">Month</label>
                <Select
                  className="w_full bg_white h_40"
                  placeholder={dict["Select"][language]}
                  value={analytic_date?.split("-")?.[1]}
                  onChange={(e) => {
                    this.handle_analytic_date(e?.target?.value, year);
                  }}
                >
                  {months.map(({ month, code }) => {
                    return (
                      <MenuItem key={month} value={code}>
                        {capitalize_first_letter(month)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div>
                <label className="text_center block w_full">Type</label>
                <Select
                  className="w_full bg_white h_40"
                  placeholder={dict["Select"][language]}
                  value={type}
                  onChange={(e) => {
                    this.handle_type(e?.target?.value);
                  }}
                >
                  {types.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {capitalize_first_letter(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div>
                <label className="text_center block w_full">Year</label>
                <Select
                  className="w_full bg_white h_40"
                  placeholder={dict["Select"][language]}
                  value={year}
                  onChange={(e) => {
                    this.setState({ year: e?.target?.value });
                  }}
                >
                  {years.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <label>Total Request:</label>
              <label className="bold text_darkBlue font_24">{total}</label>
            </div>

            <section className="scroll_x_auto ">
              <Chart
                type="bar"
                height={"auto"}
                data={{
                  labels: date_label,
                  datasets: [
                    {
                      data: values,
                      fill: true,
                      backgroundColor: "#21a5f6",
                      borderColor: "#1a649d",
                      hoverBackgroundColor: "#1a649d",
                      hoverBorderColor: "#d1f962",
                    },
                  ],
                }}
                options={options_bar}
              />
            </section>
          </>
        )}
      </div>
    );

    // Delete confirmation modal content
    const modal_delete_content = this.state.modal_delete_api_key && (
      <Modal
        modalSize="small"
        isOpen={this.state.modal_delete_api_key}
        onClose={() => this.toggle_delete_modal()}
      >
        <div className="box-body">
          <h3>
            {
              dict?.["Are you sure you want to delete this API key?"]?.[
                language
              ]
            }
          </h3>
          <button
            className="button background_blue"
            onClick={this.confirm_delete_api_key}
            style={{ marginRight: "10px" }}
          >
            {dict?.["Yes"]?.[language]}
          </button>
          <button
            className="button background_blue"
            onClick={() => this.toggle_delete_modal()}
          >
            {dict?.["No"]?.[language]}
          </button>
        </div>
      </Modal>
    );

    return (
      <main className="max_height" style={{ padding: "20px" }}>
        <h1 className="text_big">Map Services</h1>
        <h1 className="text_bold margin_bottom">
          {dict?.["Manage your API keys and analytics here"]?.[language]}
        </h1>

        {/* Render the tabs */}
        {tabs}

        {/* Render content based on the selected tab */}
        <section>
          {this.state.tab === 0
            ? api_keys_content
            : this.state.tab === 1
            ? analytics_content
            : examples_content}
        </section>

        {/* Render the delete confirmation modal */}
        {modal_delete_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map_service_reducer: state.map_service_reducer,
});

export default connect(mapStateToProps, {
  get_api_keys,
  create_api_key,
  delete_api_key,
  get_basemap_styles,
  get_monthly_report,
})(MAP_SERVICE_MENU_PAGE);
